import { configure, makeAutoObservable } from 'mobx';

import StepTypesService from '../../services/Admin/StepTypesService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export type SampleType = {};

export class StepTypesStore {
  private stepTypesService: StepTypesService;
  private ns: NotificationsStore;

  constructor(stepTypesService: StepTypesService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.stepTypesService = stepTypesService;
    this.ns = notificationStore;
  }

  data: SampleType[] = [];
  isLoading: boolean = false;

  sampleGet = async () => {
    try {
      this.isLoading = true;
      // const response = await this.stepTypesService.sampleFetch();
      // this.data = response;
      this.ns.addToast('success', 'Fetched data from StepTypesStore', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default StepTypesStore;
