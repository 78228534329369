import './BounceAndRollChart.scss';

import * as d3 from 'd3';

import { useMemo, useRef } from 'react';

import { BounceAndRoll } from 'services/Calculator/CalculatorService';
import Ground from '../CommonComponents/Ground/Ground';
import { TrajectoryPoint } from 'types/calculator/trajectoryPoint';
import { createGridValues } from 'components/FullCalculator/helpers/createGridValues';
import { getValueByPath } from 'components/FullCalculator/helpers/valueManipulators';
import { observer } from 'mobx-react-lite';
import { pixelsPerMeterOnSvg } from '../BallLaunchChart/helpers/constants';
import useEdgeValuesOfTrajectories from 'components/FullCalculator/hooks/useEdgeValuesOfTrajectories';
import { useNumericConstants } from 'components/FullCalculator/hooks/useConverterConstants';
import { useStores } from 'index';

type Props = {
  calcResult: BounceAndRoll;
};

const BounceAndRollChart = observer(({ calcResult }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const cs = useStores().calculatorStore;
  const isLoadingCalcResult = useMemo(() => cs.isLoading, [cs.isLoading]);

  const { symbol } = useNumericConstants('Distance');

  const chartDimensions = useMemo(
    () => ({ width: containerRef.current?.clientWidth ?? 1, height: containerRef.current?.clientHeight ?? 1 }),
    [containerRef.current, isLoadingCalcResult] // needed
  );

  const { xMinTraj, xMaxTraj, yMinTraj, yMaxTraj } = useEdgeValuesOfTrajectories(calcResult.Trajectory);

  const step = 0.5;
  const xMinChart = xMinTraj - 2 - step; // use step as margin and display 2 negative ticks
  const xMaxChart = Math.max(Math.ceil(xMaxTraj + 1) + step, 9.5); // use step as margin and display 1 full tick without trajectory

  const xTicks = createGridValues(xMinChart, xMaxChart, step);
  const yTicks = createGridValues(0, Math.ceil(yMaxTraj + 1), step);

  const stepWidthInPx = chartDimensions.width / xTicks.length;
  const stepHeightInPx = chartDimensions.height / yTicks.length;

  const x = d3.scaleLinear([xMinTraj, xMaxTraj], [(2.5 / step) * stepWidthInPx, ((xMaxTraj + 2.5) / step) * stepWidthInPx]);
  const y = d3.scaleLinear([yMinTraj, yMaxTraj], [0, (yMaxTraj / step) * stepHeightInPx]);

  const trajectoryLine = d3
    .line<TrajectoryPoint>()
    .x((d) => x(d.X) ?? 0)
    .y((d) => y(d.Y) ?? 0);

  return (
    <div className='BounceAndRollChart-container' ref={containerRef}>
      <Ground view='side' />
      <div className={`chart-container ${isLoadingCalcResult ? 'animate-pulse' : ''}`}>
        <div className='ticks-container'>
          {xTicks.map((tick, i) => (
            <div
              key={`xTick-${tick}`}
              style={{
                width: stepWidthInPx,
                borderLeft: i !== 0 ? '1px solid rgba(0, 0, 0, 0.09)' : '',
              }}
              className='tick'
            >
              {tick >= -1 && tick % 1 === 0 && i !== xTicks.length - 1 && <span>{tick}</span>}
              {i === xTicks.length - 1 && <span>{symbol}</span>}
            </div>
          ))}
        </div>
        <div
          style={{
            left: stepWidthInPx * 5,
            transform: `translateX(-50%) rotate(${
              -90 +
                getValueByPath({
                  readableObject: calcResult,
                  readPath: 'so:LandingData.LandingAngle',
                }) ?? 45
            }deg)`,
          }}
          className='incoming-path'
        />
        <svg className='bounce-path' width={'100%'} height={'100%'}>
          <path
            key={`side-view-bounce-and-roll-${JSON.stringify(calcResult.Trajectory.flat(1))}`} // without a key it will not rerender
            style={{
              strokeDasharray: chartDimensions.width, // estimation of path length, the values must be higher than actual length
              strokeDashoffset: chartDimensions.width, // estimation of path length, the values must be higher than actual length
              animation: 'dash 1.5s ease forwards',
            }}
            fill='none'
            stroke='currentColor'
            strokeWidth='3'
            d={trajectoryLine(calcResult.Trajectory.flat(1)) ?? undefined}
          />
        </svg>
      </div>
    </div>
  );
});

export default BounceAndRollChart;
