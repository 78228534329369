import '../AdminModal.scss';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import Modal from '@mui/material/Modal';
import { observer } from 'mobx-react-lite';

type Props = {
  isModalOn: boolean;
  setIsModalOn: (isModalOn: boolean) => void;
  jsonPreview: string;
};

export const LessonPreviewModal = observer(({ isModalOn, setIsModalOn, jsonPreview }: Props) => {
  return (
    <Modal open={isModalOn} onClose={() => setIsModalOn(false)} className='admin-info-modal preview-modal'>
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>Lesson preview</h2>
          <CloseIcon onClick={() => setIsModalOn(false)} />
        </div>
        <div className='modal-body'>
          <div className='json-wrapper'>
            <pre>{jsonPreview}</pre>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='styled-button tertiary' onClick={() => setIsModalOn(false)}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default LessonPreviewModal;
