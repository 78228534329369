import CourseSectionSkeleton from 'components/CourseSectionSkeleton/CourseSectionSkeleton';
import ModulesAndTestsSection from 'components/ModulesAndTestsSection/ModulesAndTestsSection';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const ModulesAndTests = observer(() => {
  const ds = useStores().dashboardStore;
  const ps = useStores().profileStore;
  const { t } = useTranslation();

  useSetPageTitle({
    title: t('ModulesAndTests', { ns: 'general' }),
    subtitle: t('TestYourKnowledge', { ns: 'modulesAndTests' }),
  });

  useEffect(() => {
    ps.checkIfUserIsMember();
  }, [ps]);

  const sections = ds.lessonsSectionsCollection.courses;

  useEffect(() => {
    ds.getDashboardData();
  }, [ds]);

  if (!sections.length) {
    return (
      <div style={{ padding: '40px' }}>
        {Array(3)
          .fill(1)
          .map((item, i) => (
            <CourseSectionSkeleton key={`section-${i}`} />
          ))}
      </div>
    );
  }

  return (
    <>
      {sections.map((section, index) => {
        let doneLessons = 0;

        for (let lesson of section.lessons) {
          lesson.done && doneLessons++;
        }

        let donePercentage = Math.trunc((100 * doneLessons) / section.lessons.length); //Added trunc to avoid floating point numbers as a quick fix - Maybe Kasia will find a better solution. Pawel

        return (
          <ModulesAndTestsSection
            key={section.id}
            section={section}
            index={index + 1}
            sectionsCount={sections.length}
            donePercentage={donePercentage}
          />
        );
      })}
    </>
  );
});

export default ModulesAndTests;
