import { i18nInit } from 'i18nSetup';
import i18next from 'i18next';

i18nInit();

export const clubTypes = [
  { value: 'Driver', label: i18next.t('Driver', { ns: 'calculators' }) },
  { value: '6Iron', label: i18next.t('Iron', { ns: 'calculators' }) },
  { value: 'PitchingWedge', label: i18next.t('PitchingWedge', { ns: 'calculators' }) },
] as const;

export type ClubType = (typeof clubTypes)[number]['value']; // exporting it from here makes this file the single source of truth

export const optimizationTypes = [
  { value: 'carry', label: i18next.t('Carry', { ns: 'calculators' }) },
  { value: 'combined', label: i18next.t('Combined', { ns: 'calculators' }) },
  { value: 'total', label: i18next.t('Total', { ns: 'calculators' }) },
] as const;

export const trajectoryTypes = [
  { value: 'high', label: i18next.t('HighTrajectory', { ns: 'calculators' }) },
  { value: 'medium', label: i18next.t('MidTrajectory', { ns: 'calculators' }) },
  { value: 'low', label: i18next.t('LowTrajectory', { ns: 'calculators' }) },
] as const;

export const headwindTailwind = [
  { value: '0', label: i18next.t('Headwind', { ns: 'calculators' }) },
  { value: '180', label: i18next.t('Tailwind', { ns: 'calculators' }) },
] as const;

export const crosswind = [
  { value: '90', label: i18next.t('RightToLeft', { ns: 'calculators' }) },
  { value: '270', label: i18next.t('LeftToRight', { ns: 'calculators' }) },
] as const;

export const surfaceTypes = [
  { value: 'fairway', label: i18next.t('Fairway', { ns: 'calculators' }) },
  { value: 'green', label: i18next.t('Green', { ns: 'calculators' }) },
] as const;

export const surfaceHardness = [
  { value: 'hard', label: i18next.t('HardSurfaceHardness', { ns: 'calculators' }) },
  { value: 'medium', label: i18next.t('MediumSurfaceHardness', { ns: 'calculators' }) },
  { value: 'soft', label: i18next.t('SoftSurfaceHardness', { ns: 'calculators' }) },
] as const;
