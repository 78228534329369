import '../AdminModal.scss';

import { useCallback, useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import { Loader } from '@trackman/web-shared-components';
import Modal from '@mui/material/Modal';
import Paragraph from 'components/TextComps/Paragraph';
import { TextArea } from '@trackman/web-shared-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const InviteInstructorsModal = observer(() => {
  const os = useStores().organizationsEditorStore;
  const [inputEmails, setInputEmails] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const lookupEmailsAndAddInstructors = useCallback(() => {
    setErrorMessage('');
    os.emailsToCheck = inputEmails
      .split('\n')
      .filter((inputEmail) => !os.organization.instructors.some((instructor) => instructor.email === inputEmail.trim()));

    setInputEmails(os.emailsToCheck.join('\n'));

    if (os.emailsToCheck.length > 0) {
      os.instructorsEmailLookup(os.emailsToCheck);
    } else {
      setErrorMessage('The email(s) you entered are already in the list');
    }
  }, [inputEmails, os]);

  useEffect(() => {
    os.notFoundInstructors.length > 0
      ? setErrorMessage(`The following email(s) are not registered: ${os.notFoundInstructors.join(', ')}`)
      : setErrorMessage('');
  }, [os.notFoundInstructors]);

  useEffect(() => {
    !os.isLookingUpEmails && setInputEmails('');
  }, [os.isLookingUpEmails]);

  const onModalClose = () => {
    os.isInviteInstructorsModalOpen = false;
    setInputEmails('');
  };

  return (
    <Modal open={os.isInviteInstructorsModalOpen} onClose={() => onModalClose()} className='admin-modal invite-instructors-modal'>
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>Invite instructors</h2>
          <CloseIcon onClick={() => (os.isInviteInstructorsModalOpen = false)} />
        </div>
        <div className='modal-body tmu-table-wrapper admin-edit-section'>
          {os.isLookingUpEmails ? (
            <div className='loader-wrapper' style={{ zIndex: 10 }}>
              <Loader size='large' />
            </div>
          ) : (
            <></>
          )}
          <h2>Invite instructors by email</h2>
          <div className='inputs-container'>
            <Paragraph>
              <>
                To be eligible for an invitation, the invitee must have a (free) TMU account. Sign-ups are done at TrackamanUniversity.com.
                Click{' '}
                <a href='https://login.trackmangolf.com/Account/Register' title='Go to sign up page' about='_blank'>
                  here
                </a>{' '}
                to sign up an instructor.
              </>
            </Paragraph>
            {errorMessage !== '' ? (
              <Paragraph>
                <>{errorMessage}</>
              </Paragraph>
            ) : (
              <></>
            )}
            <TextArea
              label={'Emails'}
              value={inputEmails}
              onChange={(e) => {
                setErrorMessage('');
                setInputEmails(e.target.value.replace(/,|\s+/g, '\n'));
              }}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <button className='styled-button tertiary' onClick={() => onModalClose()}>
            Cancel
          </button>
          <button className='styled-button primary' onClick={() => lookupEmailsAndAddInstructors()} disabled={errorMessage !== ''}>
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default InviteInstructorsModal;
