import './LessonTile.scss';

import { DefaultRoutes } from 'Routes';
import { Lesson } from 'types/lessons';
import LessonTileOverlay from './LessonTileOverlay/LessonTileOverlay';
import LessonTileProgressBar from './LessonTileProgressBar/LessonTileProgressBar';
import { Link } from 'react-router-dom';
import { ReactComponent as OpenBook } from '../../images/icons/OpenBook.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type LessonTileProps = {
  lesson: Lesson;
  lessonNumber: number;
  color: string;
  courseId: string;
  sectionTitle?: string;
  bookIcon?: boolean;
  isModulesAndTests?: boolean;
  isPrevLessonDone?: boolean | null;
  comingSoon?: boolean;
};

export const LessonTile = observer(
  ({
    lesson,
    lessonNumber,
    color,
    courseId,
    sectionTitle,
    bookIcon = false,
    isModulesAndTests = false,
    isPrevLessonDone,
    comingSoon,
  }: LessonTileProps) => {
    const ps = useStores().profileStore;
    const { progress, title, prerequisites, certification } = lesson;
    const isDisabled = !isPrevLessonDone && prerequisites && prerequisites.length > 0;
    const progressPercentage = Math.floor(progress * 100);
    let tileLink = `${courseId}/${lesson.id}`;

    if (isDisabled || comingSoon || (certification && !ps.isUserMember)) tileLink = '';
    if (certification && !ps.isUserMember) tileLink = DefaultRoutes.Certifications;

    return (
      <div
        className={`lesson-tile ${isDisabled || (!ps.isUserMember && isModulesAndTests && certification) ? 'disabled' : ''} ${
          comingSoon ? 'coming-soon' : ''
        }`}
        style={{ backgroundColor: `${color}20` }}
      >
        <Link to={tileLink} state={{ sectionTitle, lessonNumber }}>
          {(certification && !ps.isUserMember) || isDisabled ? (
            <LessonTileOverlay
              color={color}
              prerequisites={prerequisites}
              isDisabled={isDisabled}
              certification={certification}
              hasMembership={ps.isUserMember}
            />
          ) : (
            <></>
          )}
          <div className='lesson-description'>
            <div className='lesson-top-description'>
              <div className='lesson-number' style={{ color: `${color}` }}>
                {lessonNumber}
              </div>
              {bookIcon && <OpenBook style={{ fill: `${color}40` }} />}
            </div>
            <div className='title'>{title.toLowerCase()}</div>
          </div>
          {comingSoon ? (
            <div className='coming-soon-info'>
              <span style={{ color: color }}>Coming soon</span>
            </div>
          ) : (
            <LessonTileProgressBar progress={progressPercentage} color={color} />
          )}
        </Link>
      </div>
    );
  }
);

export default LessonTile;
