import { AxiosInstance } from 'axios';

export class AccountService {
    private axiosInstance: AxiosInstance;
    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    accountRegister = async (email, password, confirmPassword) => {
        const response = await this.axiosInstance.post(`/api/Account/register`, {
            Email: email,
            Password: password,
            ConfirmPassword: confirmPassword,
        });
        if (response.data && !response.data.isError && response.data.data) {
            return response.data.data;
        }
        throw Error('There was an internal error while registering an account.');
    };

    logOut = async () => {
        window.location.assign(this.axiosInstance.defaults.baseURL + `/account/logout`);
    };

    createUser = async () => {
        const response = await this.axiosInstance.post(`/api/account/CreateUser`);
        if (response.data && !response.data.isError && response.data.data) {
            return response.data.data;
        }
        throw Error('There was an internal error while creating user.');
    };
}

export default AccountService;