import './Tabs.scss';

import { DefaultRouteKey, DefaultRoutes } from 'Routes';
import { NavLink, Outlet } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { ReactComponent as Arrow } from '../../images/icons/Icon_Left.svg';
import { useLocation } from 'react-router-dom';

interface TabsProps<TabRoute extends DefaultRouteKey, TabName extends string> {
  tabs: { [key in TabRoute]: TabName };
  defaultTabName: TabName;
}

export const Tabs = <TabRoute extends DefaultRouteKey, TabName extends string>({ tabs, defaultTabName }: TabsProps<TabRoute, TabName>) => {
  const [activeTabName, setActiveTabName] = useState<TabName>(defaultTabName);
  const location = useLocation();

  useEffect(() => {
    const tabNameFromLocation = location.pathname.split('/').at(-1) as TabRoute;
    const tabNameFromMainPartOfUrl = location.pathname.split('/').at(1) as TabRoute;

    setActiveTabName(
      tabs?.[tabNameFromLocation]
        ? tabs?.[tabNameFromLocation]
        : tabs?.[tabNameFromMainPartOfUrl]
        ? tabs?.[tabNameFromMainPartOfUrl]
        : defaultTabName
    );
  }, [location.pathname, tabs]);

  const [isMobileTabsOpened, setMobileTabsOpened] = useState(false);

  const tabRoutes = useMemo(() => Object.keys(tabs) as TabRoute[], [tabs]);

  return (
    <div className='tabs-content-container'>
      <div
        className={`tabs-current-pill ${isMobileTabsOpened ? 'tabs-mobile-opened' : ''}`}
        onClick={() => setMobileTabsOpened(!isMobileTabsOpened)}
      >
        {activeTabName}
        <Arrow />
      </div>
      <div className={`mobile-dropdown-container ${isMobileTabsOpened ? 'tabs-mobile-opened' : ''}`}>
        <ul className='tabs-container'>
          {tabRoutes.map((tabRoute) => (
            <li
              className={`tabs-tab ${activeTabName === tabs[tabRoute] ? 'tab-active' : ''}`}
              key={tabRoute}
              onClick={() => {
                setMobileTabsOpened(false);
                setActiveTabName(tabs[tabRoute]);
              }}
            >
              <NavLink to={DefaultRoutes[tabRoute]}>{tabs[tabRoute]}</NavLink>
            </li>
          ))}
        </ul>
      </div>
      <Outlet />
    </div>
  );
};

export default Tabs;
