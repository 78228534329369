import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../../NotificationsStore';
import QuestionEditorService from '../../../services/Admin/Editors/QuestionEditorService';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export type SampleType = {};

export class QuestionEditorStore {
  private questionEditorService: QuestionEditorService;
  private ns: NotificationsStore;

  constructor(questionEditorService: QuestionEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.questionEditorService = questionEditorService;
    this.ns = notificationStore;
  }

  data: SampleType[] = [];
  isLoading: boolean = false;

  sampleGet = async () => {
    try {
      this.isLoading = true;
      // const response = await this.questionEditorService.sampleFetch();
      // this.data = response;
      this.ns.addToast('success', 'Fetched data from QuestionEditorStore', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default QuestionEditorStore;
