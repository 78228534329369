import { configure, makeAutoObservable } from 'mobx';

import AwardEditorService from '../../../services/Admin/Editors/AwardEditorService';
import { NotificationsStore } from '../../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export type SampleType = {};

export class AwardEditorStore {
  private awardEditorService: AwardEditorService;
  private ns: NotificationsStore;

  constructor(awardEditorService: AwardEditorService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.awardEditorService = awardEditorService;
    this.ns = notificationStore;
  }

  data: SampleType[] = [];
  isLoading: boolean = false;

  sampleGet = async () => {
    try {
      this.isLoading = true;
      // const response = await this.awardEditorService.sampleFetch();
      // this.data = response;
      this.ns.addToast('success', 'Fetched data from AwardEditorStore', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default AwardEditorStore;
