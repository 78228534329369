import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../NotificationsStore';
import { PartnerOrMaster } from 'types/partner';
import PartnersService from '../../services/Partners/PartnersService';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class PartnersStore {
  private partnersService: PartnersService;
  private ns: NotificationsStore;

  constructor(partnersService: PartnersService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.partnersService = partnersService;
    this.ns = notificationStore;
  }

  partners: PartnerOrMaster[] | number[] = [0, 1, 2, 3, 4, 5, 6, 7]; // generate 8 skeletons until Partners load
  isLoading: boolean = false;

  getPartners = async () => {
    try {
      this.isLoading = true;
      const response = await this.partnersService.getPartners();
      this.partners = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default PartnersStore;
