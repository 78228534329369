import { AxiosInstance } from 'axios';

export class LtiProbeService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getQuestions = async () => {
    const response = await this.axiosInstance.get(`/api/v2/questions`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching questions.');
  };

  postQuestions = async (data) => {
    const response = await this.axiosInstance.post(`/api/v2/questions`, data);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while posting questions.');
  };

  checkAnswer = async (questionId, userAnswer, ltiSourcedId, substitutionDataRowId) => {
    const response = await this.axiosInstance.post(`/api/v2/questions/check-user-answer`, {
        "questionId": questionId, 
        "userAnswer": userAnswer,
        "ltiSourcedId": ltiSourcedId,
        "substitutionDataRowId": substitutionDataRowId 
    });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while checking the answer.');
  };

  loadHintForQuestion = async (learningMaterialId, substitutionDataRowId) => {
    const response = await this.axiosInstance.get(`/api/v2/learning-materials/${learningMaterialId}?substitutionDataRowId=${substitutionDataRowId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching questions.');
  };

  continue = async (url) => {
    const response = await this.axiosInstance.post(url);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while continuing.');
  };
}

export default LtiProbeService;
