import './StudentsTab.scss';
import '../../Organization.scss';

import InviteStudentsModal from 'components/Organization/Modals/InviteStudentsModal/InviteStudentsModal';
import { Loader } from '@trackman/web-shared-components';
import NoClassesFound from 'components/Organization/NoClassesFound/NoClassesFound';
import NoStudentsFound from 'components/Organization/NoStudentsFound/NoStudentsFound';
import OrganizationSearchbar from 'components/Organization/OrganizationSearchbar/OrganizationSearchbar';
import SearchForStudents from 'components/Organization/OrganizationSearchbar/SearchForStudents/SearchForStudents';
import SelectClass from 'components/Organization/OrganizationSearchbar/SelectClass/SelectClass';
import StudentsList from 'components/Organization/StudentsList/StudentsList';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const StudentsTab = observer(() => {
  const { t } = useTranslation();
  const cs = useStores().classesStore;
  const iss = useStores().inviteStudentsStore;
  const ss = useStores().studentsStore;
  const orgStore = useStores().organizationsStore;

  useEffect(() => {
    if (orgStore.selectedOrg.id) {
      ss.getStudents();
      iss.getInvites();
      iss.getNumberOfInvitesLeft();
    }
  }, [ss, iss, orgStore.selectedOrg.id]);

  const handleSelectChange = (newValue: string) => {
    const classItem = cs.classesList.find((classItem) => classItem.id === newValue);
    iss.selectedClass = { id: newValue, name: classItem ? classItem.name : '' };
    cs.selectedClassId = newValue;
    ss.getStudents();
    iss.getInvites();
  };

  return (
    <>
      {ss.isLoading ? (
        <div className='loader-wrapper'>
          <Loader size='large' />
        </div>
      ) : (
        <>
          {!cs.classesList.length ? (
            <NoClassesFound />
          ) : !ss.students.length && !iss.invites.length ? (
            <NoStudentsFound />
          ) : (
            <>
              <OrganizationSearchbar heading={t('StudentAndClassProgress', { ns: 'organizationPage' })}>
                <SelectClass onChange={(newValue) => handleSelectChange(newValue)} value={cs.selectedClassId ?? ''} />
                {ss.isSeeStudentsTabActive ? (
                  <SearchForStudents
                    label={t('SearchForStudentsAndInvites', { ns: 'studentsPage' })}
                    input={ss.searchInput}
                    onChange={(newValue: string) => (ss.searchInput = newValue)}
                  />
                ) : (
                  <SearchForStudents
                    label={t('SearchForStudentsAndInvites', { ns: 'studentsPage' })}
                    input={iss.searchInput}
                    onChange={(newValue: string) => (iss.searchInput = newValue)}
                  />
                )}
              </OrganizationSearchbar>
              <div className='students-list-table org-table'>
                {!ss.isLoading ? (
                  <StudentsList />
                ) : (
                  <div className='loader-wrapper'>
                    <Loader size='large' />
                  </div>
                )}
              </div>
            </>
          )}
          <InviteStudentsModal />
        </>
      )}
    </>
  );
});

export default StudentsTab;
