import * as React from 'react';

import { ApplicationUserRights } from 'stores/ApplicationUserRightsStore';

interface IProps {
  need: string[];
  children?: React.ReactNode;
}

export const Can: React.FC<IProps> = (props) => {
  const { need } = props;
  const allowed = true; //ApplicationUserRights.IsAllowed(need);

  const renderChildren = () => {
    const { children } = props;
    return React.Children.only(children);
  };
  return <>{allowed ? renderChildren() : null}</>;
};

export const Cannot: React.FC<IProps> = (props) => {
  const { need } = props;
  const allowed = true; //ApplicationUserRights.IsAllowed(need);

  const renderChildren = () => {
    const { children } = props;
    return React.Children.only(children);
  };
  return <>{!allowed ? renderChildren() : null}</>;
};
