import { configure, makeAutoObservable } from 'mobx';

import { AdminImageType } from 'types/admin/media';
import MediaService from '../../services/Admin/MediaService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class MediaStore {
  private mediaService: MediaService;
  private ns: NotificationsStore;

  constructor(mediaService: MediaService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.mediaService = mediaService;
    this.ns = notificationStore;
  }

  imageId: string = '';
  cleanImageData: AdminImageType = {
    id: '',
    originalFilename: '',
    folderPath: null,
    fileUrl: '',
    mimeType: '',
    dataForSmallFiles: '',
  };
  imageData = { ...this.cleanImageData };
  deleteImageId: string = '';
  imagesInCurrentFolder: AdminImageType[] = [];
  isLoading: boolean = false;

  getMedia = async () => {
    try {
      this.isLoading = true;
      const response = await this.mediaService.getMedia(this.imageId);
      this.imageData = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteMedia = async () => {
    try {
      this.isLoading = true;
      await this.mediaService.deleteMedia(this.deleteImageId);
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
  };

  getMediaInCurrentFolder = async (path: string) => {
    try {
      this.isLoading = true;
      const response = await this.mediaService.getMediaInCurrentFolder(path);
      this.imagesInCurrentFolder = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default MediaStore;
