import { LanguageInfo, LanguageType } from 'types/languages';

import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';
import i18next from 'i18next';

export class LanguagesService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  loadLanguages = async () => {
    const response = await this.axiosInstance.get<Result<LanguageInfo[]>>(`/api/Language`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('LanguagesLoadingError', { ns: 'languages' }));
  };

  getUserLanguage = async () => {
    const response = await this.axiosInstance.get<Result<LanguageType>>(`/api/profile/GetLanguage`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('GettingUserError', { ns: 'languages' }));
  };

  changeLanguage = async (countryCode: LanguageType) => {
    const response = await this.axiosInstance.get(`/api/profile/SetLanguage?savedLanguage=${countryCode}`);

    if (response.status !== 200 || response.data !== '') throw Error(i18next.t('ChangingUserError', { ns: 'languages' }));
  };
}

export default LanguagesService;
