import './LearningMaterialsLessonPage.scss';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as Arrow } from 'images/icons/Icon_Left.svg';
import { ReactComponent as Checkmark } from 'images/icons/Checkmark.svg';
import { DefaultRoutes } from 'Routes';
import { LearningMaterial } from 'types/learningMaterials';
import LearningMaterialsLessonCalculator from 'components/LearningMaterialsRoom/LearningMaterialsLesson/LearningMaterialsLessonCalculator/LearningMaterialsLessonCalculator';
import LearningMaterialsLessonPicture from 'components/LearningMaterialsRoom/LearningMaterialsLesson/LearningMaterialsLessonPicture/LearningMaterialsLessonPicture';
import LearningMaterialsLessonText from 'components/LearningMaterialsRoom/LearningMaterialsLesson/LearningMaterialsLessonText/LearningMaterialsLessonText';
import LearningMaterialsLessonVideo from 'components/LearningMaterialsRoom/LearningMaterialsLesson/LearningMaterialsLessonVideo/LearningMaterialsLessonVideo';
import { Loader } from '@trackman/web-shared-components';
import { ReactComponent as OpenBook } from 'images/icons/OpenBook.svg';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

type LocationState = {
  sectionTitle: string;
  lessonNumber: number;
};

export const LearningMaterialsLessonPage = observer(() => {
  const [currentLessonItemStep, setCurrentLessonItemStep] = useState(0);
  const ls = useStores().learningMaterialsRoomLessonStore;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { courseId, lessonId } = useParams();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [learningMaterials, setLearningMaterials] = useState<Array<LearningMaterial>>([]);
  const currentLesson: LearningMaterial | undefined = ls.learningMaterialLesson.learningMaterial[currentLessonItemStep];

  useSetPageTitle({
    title: t('LearningMaterials', { ns: 'general' }),
    subtitle: t('GetABetterUnderstanding', { ns: 'learningMaterials' }), //@todo translate
  });

  useEffect(() => {
    courseId && lessonId && ls.startLesson(courseId, lessonId);
  }, [courseId, lessonId, ls]);

  useEffect(() => {
    setLearningMaterials(ls.learningMaterialLesson.learningMaterial);
  }, [ls.learningMaterialLesson.learningMaterial, ls.learningMaterialLesson.learningMaterial.length]);

  const markLessonItemAsDone = () => courseId && lessonId && ls.markLessonItemAsDone(courseId, lessonId, currentLesson.id);

  const isAllLessonItemsDone = ls.learningMaterialLesson.learningMaterial.every(
    (currentValue: LearningMaterial) => currentValue.done === true
  );

  const handleContinue = async () => {
    markLessonItemAsDone();

    if (
      currentLessonItemStep < ls.learningMaterialLesson.learningMaterial.length - 1 ||
      (ls.learningMaterialLesson.learningMaterial.length !== 1 && currentLessonItemStep === 0)
    ) {
      setCurrentLessonItemStep((prevStep) => prevStep + 1);
      setLessonItemDisplayAsDone();
    } else {
      if (!isAllLessonItemsDone && courseId && lessonId) await ls.markLessonAsDone(courseId, lessonId);
      navigate(DefaultRoutes.LearningMaterials);
    }
  };

  const handleGoBack = () => {
    setCurrentLessonItemStep((prevStep) => prevStep - 1);
  };

  const handleNavigate = (step: number) => {
    setLessonItemDisplayAsDone();
    markLessonItemAsDone();
    setCurrentLessonItemStep(step);
  };

  const setLessonItemDisplayAsDone = () => {
    const newLearningMaterials = [...learningMaterials];
    newLearningMaterials[currentLessonItemStep].done = true;
    setLearningMaterials(newLearningMaterials);
  };

  return (
    <div className='learning-materials-lesson-container'>
      {ls.isLoading || ls.isMarkingLessonAsDone || currentLesson === undefined ? (
        <div className='loader-wrapper'>
          <Loader size='large' />
        </div>
      ) : (
        <>
          <div className='content-box'>
            <div className='lesson-title-wrapper'>
              <div className='book-icon'>
                <OpenBook style={{ fill: `${ls.learningMaterialLesson.color}` }} />
              </div>
              <h2>{currentLesson.title.toLowerCase()}</h2>
            </div>
            {currentLesson.kind.toLowerCase() === 'video' && currentLesson.youTubeId && (
              <LearningMaterialsLessonVideo youTubeId={currentLesson.youTubeId} title={currentLesson.title} />
            )}
            {currentLesson.kind.toLowerCase() === 'text' && <LearningMaterialsLessonText description={currentLesson.description} />}
            {currentLesson.kind.toLowerCase() === 'picture' && currentLesson.media && (
              <LearningMaterialsLessonPicture imageUrl={currentLesson.media.fileUrl} imageTitle={currentLesson.title} />
            )}
            {currentLesson.kind.toLowerCase() === 'calculator' && currentLesson.calculatorOptions !== null && (
              <LearningMaterialsLessonCalculator
                calculatorKind={currentLesson.calculatorKind}
                calculatorOptions={currentLesson.calculatorOptions}
                htmlColor={ls.learningMaterialLesson.color ?? '#467d80'}
              />
            )}

            <div className='navigation-container'>
              {currentLessonItemStep + 1 > 1 && (
                <button onClick={handleGoBack} className='back-button'>
                  <Arrow className='back-arrow' />
                  <span>{t('Back', { ns: 'general' })}</span>
                </button>
              )}
              <button onClick={() => navigate(DefaultRoutes.LearningMaterials)}>{t('Quit', { ns: 'general' })}</button>
              <button onClick={handleContinue} className='continue-button'>
                <span>{t('Continue', { ns: 'general' })}</span>
                <Arrow className='forward-arrow' />
              </button>
            </div>
            <div className='bottom-info'>
              <h3>{locationState.sectionTitle}</h3>
              <p className='lesson-title'>
                <strong>
                  <span style={{ color: ls.learningMaterialLesson.color }}>{locationState.lessonNumber}</span>{' '}
                  {ls.learningMaterialLesson.title}
                </strong>
              </p>
              <p className='lesson-item-title'>
                {ls.learningMaterialLesson.learningMaterial.indexOf(currentLesson) + 1}. {currentLesson.title.toLowerCase()}
              </p>
            </div>
          </div>
          <div className='lesson-items-list'>
            <h2 className='lesson-items-heading'>
              {currentLesson.title.toLowerCase()}
              <span>
                {currentLessonItemStep + 1}/{ls.learningMaterialLesson.learningMaterial.length}
              </span>
            </h2>
            <ul className='styled-scrollbar' style={{ color: `${ls.learningMaterialLesson.color}` }}>
              {learningMaterials.map((item, index) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => handleNavigate(index)}
                    className={`lesson-item ${currentLesson.id === item.id ? 'current-lesson' : ''} ${item.done ? 'lesson-done' : ''}`}
                  >
                    <div className='lesson-info-wrapper'>
                      {item.done && !(currentLesson.id === item.id) && (
                        <Checkmark style={{ backgroundColor: `${ls.learningMaterialLesson.color}` }} />
                      )}
                      {currentLesson.id === item.id && (
                        <span
                          className='lesson-item-number'
                          style={{ backgroundColor: `${ls.learningMaterialLesson.color}`, color: '#fff' }}
                        >
                          {index + 1}
                        </span>
                      )}
                      {!item.done && !(currentLesson.id === item.id) && index + 1 && (
                        <span
                          className='lesson-item-number'
                          style={{ backgroundColor: '#fff', color: `${ls.learningMaterialLesson.color}` }}
                        >
                          {index + 1}
                        </span>
                      )}
                      <span className='lesson-item-title'>{item.title.toLowerCase()}</span>
                    </div>
                    {ls.learningMaterialLesson.learningMaterial.length - 1 > index && <div className='separator'></div>}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
});

export default LearningMaterialsLessonPage;
