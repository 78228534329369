import '../../Admin.scss';
import '../../../../styles/_table.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from 'images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from 'images/icons/ArrowUp.svg';
import { DefaultRoutes } from 'Routes';
import DeleteListItemModal from 'components/Admin/Modals/DeleteListItemModal/DeleteListItemModal';
import { ReactComponent as InfoIcon } from 'images/icons/Info.svg';
import { LanguageType } from 'types/languages';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { getLanguageText } from '../../../../utilities/languages';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  title: string;
  languages: string;
  description: string;
};

export const StudyCoursesList = observer(() => {
  useSetPageTitle({ title: 'Study Courses', subtitle: '' });
  const studyCoursesStore = useStores().studyroomCourseEditorStore;
  const [isModalOn, setIsModalOn] = useState(false);

  const deleteCourse = useCallback(() => {
    studyCoursesStore.deleteStudyCourseAndUpdate();
    setIsModalOn(false);
  }, [studyCoursesStore, setIsModalOn]);

  useEffect(() => {
    studyCoursesStore.studyCoursesList.length === 0 && studyCoursesStore.getStudyCourses();
  }, [studyCoursesStore]);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      studyCoursesStore.studyCoursesList.map((course) => {
        return {
          id: course.id,
          title: course.titles.find((title) => title.countryCode === 'en-US')?.text,
          languages: course.languages ? course.languages.map((lng: LanguageType) => getLanguageText(lng)).join(', ') : '',
          description: course.descriptions.find((desc) => desc.countryCode === 'en-US')?.text,
        };
      }) as RowsDisplay[],
    [studyCoursesStore.studyCoursesList]
  );

  return (
    <div className='admin-list-container admin-content-container'>
      <div className='admin-list-header'>
        <div className='admin-list-header-button'>
          <Link to={`/Admin/${DefaultRoutes.StudyCourseEditor}`} className='styled-button primary link-button'>
            <PlusIcon />
            <span>Create new</span>
          </Link>
        </div>
      </div>

      <div className='tmu-table-wrapper'>
        <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Name</TableCell>
              <TableCell colSpan={3}>Languages</TableCell>
              <TableCell colSpan={1}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplay.length ? (
              rowsDisplay.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell colSpan={2}>
                      <div className='description-tooltip'>
                        {row.title.toLowerCase()}
                        <Tooltip
                          title={row.description ? row.description : 'No description'}
                          placement='bottom'
                          className='table-cell-tooltip'
                        >
                          <InfoIcon />
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell colSpan={3}>{row.languages}</TableCell>
                    <TableCell colSpan={1}>
                      <div className='action-buttons'>
                        <Link to={`/Admin/${DefaultRoutes.StudyCourseEditor}/${row.id}`}>
                          <PencilIcon />
                        </Link>
                        <button
                          className='row-button'
                          style={index === 0 ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                          onClick={() => (index !== 0 ? studyCoursesStore.updateSortOrder(row.id, true) : {})}
                        >
                          <ArrowUpIcon />
                        </button>
                        <button
                          className='row-button'
                          style={index === studyCoursesStore.studyCoursesList.length - 1 ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                          onClick={() =>
                            index !== studyCoursesStore.studyCoursesList.length - 1 ? studyCoursesStore.updateSortOrder(row.id, false) : {}
                          }
                        >
                          <ArrowDownIcon />
                        </button>
                        <button
                          className='row-button'
                          onClick={() => {
                            studyCoursesStore.selectedCourseId = row.id;
                            setIsModalOn(true);
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Paragraph>
                    <>No courses found</>
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DeleteListItemModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} deleteAction={deleteCourse} itemName='course' />
    </div>
  );
});

export default StudyCoursesList;
