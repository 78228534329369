import { AxiosInstance } from 'axios';

export class QuestionEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getQuestion = async (id) => {
    const response = await this.axiosInstance.get(`/api/v2/admin/questions/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error getting the question.');
  };

  getQuestions = async () => {
    const response = await this.axiosInstance.get(`/api/v2/admin/questions/`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error getting questions.');
  };

  getAllQuestionCategories = async () => {
    const response = await this.axiosInstance.get(`/api/Basisdata/getAllQuestionCategories`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting all question categories.');
  };

  saveNewQuestionTemplate = async (id) => {
    const response = await this.axiosInstance.post(`/api/v2/admin/questions/`, { "data": id});
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error saving new question template.');
  };

  saveExistingQuestionTemplate = async (id) => {
    const response = await this.axiosInstance.put(`/api/v2/admin/questions/`, { "data": id});
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error saving existing question template.');
  };

  deleteQuestion = async (id) => {
    const response = await this.axiosInstance.delete(`/api/v2/admin/questions/${id}`, {});
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error deleting question.');
  };
}

export default QuestionEditorService;
