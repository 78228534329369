import { AxiosInstance } from 'axios';

export class AwardEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getAwardsList = async () => {
    const response = await this.axiosInstance.get(`/api/v2/admin/awards`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching awards list.');
  };

  getAward = async (awardId) => {
    const response = await this.axiosInstance.get(`/api/v2/admin/awards/${awardId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching award.');
  };

  deleteAward = async (deleteElementId) => {
    const response = await this.axiosInstance.post(`/api/v2/admin/awards/${deleteElementId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while deleting confirmed element.');
  };

  saveNewAward = async (id) => {
    const response = await this.axiosInstance.post(`/api/v2/admin/awards`, {"data": id});
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving new award.');
  };

  saveExistingAward = async (id) => {
    const response = await this.axiosInstance.put(`/api/v2/admin/awards`, {"data": id});
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving existing award.');
  };

  updateNewSortOrder = async (id: string, direction: string) => {
    const response = await this.axiosInstance.post(`/api/v2/admin/awards/sort`, { "AwardId": id, "MoveUp": direction === "up" });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while updating new awards sort order.');
  };
}

export default AwardEditorService;
