import { makeAutoObservable } from 'mobx';

class AuthorizationStore {
  isLoggedIn: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoggedIn(value: boolean) {
    this.isLoggedIn = value;
  }
}

export const auth = new AuthorizationStore();
