import '../../Admin.scss';

import { useEffect, useMemo, useState } from 'react';

import { DefaultRoutes } from 'Routes';
import { Link } from 'react-router-dom';
import Paragraph from 'components/TextComps/Paragraph';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditQuestion = observer(() => {
  useSetPageTitle({ title: 'Edit Question', subtitle: '' });

  useEffect(() => {}, []);

  return <div className='admin-list-container admin-content-container'>Edit Question</div>;
});

export default EditQuestion;
