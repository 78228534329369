import { AxiosInstance, AxiosResponse } from 'axios';

import { AuthenticationService } from './AuthenticationService';
import { ErrorHandler } from './Result';

export class AxiosServiceConfig {
  static WaitingForToken: boolean = false;
  static setDefaultDecorators = (
    axiosInstance: AxiosInstance,
    authenticationService: AuthenticationService,
    errorHandler: ErrorHandler
  ) => {
    const requestDefaultErrorHandler = (error: any) => {
      // Do something with request error
      return Promise.reject(error);
    };

    const responseDefaultErrorHandler = (error: any) => {
      // let errorTransformed = errorHandler.handleError(error);
      return Promise.resolve(error.response);
    };

    const responseDefaultDecorator = (response: AxiosResponse<any>) => {
      if (response.headers['content-type'] && response.headers['content-type'].indexOf('application/json') > -1) {
        response.data = {
          isError: false,
          data: response.data,
        };
      }
      return response;
    };

    const requestDefaultDecorator = (request: any) => {
      return request;
      return waitForToken().then(() => {
        return requestDecorator(request);
      });
    };

    const requestDecorator = (request: any) => {
      const originalRequest = request;
      const hasToken = authenticationService.hasToken();
      const authData = authenticationService.getAuthData();

      if (hasToken && authData) {
        // Add token before request is sent
        request.headers['Authorization'] = 'Bearer ' + authData.accessToken;
        AxiosServiceConfig.WaitingForToken = false;
        return request;
      }
      return authenticationService.getToken().then(() => {
        const hasToken = authenticationService.hasToken();
        const authData = authenticationService.getAuthData();
        AxiosServiceConfig.WaitingForToken = false;
        if (hasToken && authData) {
          originalRequest.headers['Authorization'] = 'Bearer ' + authData.accessToken;
          return Promise.resolve(originalRequest);
        }
        return Promise.reject(originalRequest);
      });
    };

    const waitForToken = () => {
      return new Promise(function (resolve, reject) {
        (function checkToken() {
          if (!AxiosServiceConfig.WaitingForToken) {
            AxiosServiceConfig.WaitingForToken = true;
            return resolve(undefined);
          }
          setTimeout(checkToken, 20);
        })();
      });
    };

    // Add a request and response interceptor
    axiosInstance.interceptors.request.use(requestDefaultDecorator, requestDefaultErrorHandler);
    axiosInstance.interceptors.response.use(responseDefaultDecorator, responseDefaultErrorHandler);
  };
}
