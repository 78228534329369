import { AxiosInstance } from 'axios';

export class AdminSearchService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  loadData = async () => {
    const response = await this.axiosInstance.get(`/api/v2/admin/search`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while loading admin data.');
  };
}

export default AdminSearchService;
