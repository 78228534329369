import { configure, makeAutoObservable } from 'mobx';

import { NotificationsStore } from '../NotificationsStore';
import PasswordService from '../../services/UserAccount/PasswordService';
import { getErrorMessage } from 'utilities/ErrorHelper';
import i18next from 'i18next';

configure({ enforceActions: 'never' });

export class PasswordStore {
  private passwordService: PasswordService;
  private ns: NotificationsStore;

  constructor(passwordService: PasswordService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.passwordService = passwordService;
    this.ns = notificationStore;
  }

  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  isLoading: boolean = false;

  changePassword = async () => {
    try {
      this.isLoading = true;
      await this.passwordService.changePassword(this.oldPassword, this.newPassword, this.confirmPassword);
      this.ns.addToast('success', i18next.t('YourPasswordHasBeenUpdated', { ns: 'landingPage' }), 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default PasswordStore;
