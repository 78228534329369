import { AxiosInstance } from 'axios';

export class ExternalLoginService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  externalLogin = async () => {
    const response = await this.axiosInstance.post(`/api/Account/ExternalLoginCallback`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while external login attempt.');
  };

  linkLogin = async () => {
    const response = await this.axiosInstance.post(`/api/Account/LinkLoginCallback`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while link login attempt.');
  };

  externalLoginConfirmation = async (email, emailIsChanged) => {
    const response = await this.axiosInstance.post(`/api/Account/ExternalLoginConfirmation`, {
        "Email": email,
        "EmailIsChanged": emailIsChanged
    });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while sending external login confirmation.');
  };
}

export default ExternalLoginService;
