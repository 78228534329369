import { LearningMaterialModel, LearningMaterialTypeList, LearningMaterialsList } from 'types/admin/learningMaterials';

import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';

export class LearningMaterialService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getLearningMaterials = async () => {
    const response = await this.axiosInstance.get<Result<LearningMaterialsList>>(`/api/admin/learning-materials`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting learning materials.');
  };

  getLearningMaterial = async (id: string) => {
    const response = await this.axiosInstance.get<Result<LearningMaterialModel>>(`/api/admin/learning-materials/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting learning material.');
  };

  deleteLearningMaterial = async (id: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/learning-materials/${id}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting learning material.');
  };

  saveNewLearningMaterial = async (data: LearningMaterialModel) => {
    const response = await this.axiosInstance.post<Result<LearningMaterialModel>>(`/api/admin/learning-materials`, { ...data });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving new learning material.');
  };

  saveExistingLearningMaterial = async (data: LearningMaterialModel) => {
    const response = await this.axiosInstance.put<Result<LearningMaterialModel>>(`/api/admin/learning-materials`, { ...data });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving existing learning material.');
  };

  getAllLearningMaterialTypes = async () => {
    const response = await this.axiosInstance.get<Result<LearningMaterialTypeList>>(
      `/api/admin/basis-data/get-all-learning-material-types`
    );
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting all learning material types.');
  };
}

export default LearningMaterialService;
