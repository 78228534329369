import { configure, makeAutoObservable } from 'mobx';

import AdminSearchService from '../../services/Admin/AdminSearchService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export type SampleType = {};

export class AdminSearchStore {
  private adminSearchService: AdminSearchService;
  private ns: NotificationsStore;

  constructor(adminSearchService: AdminSearchService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.adminSearchService = adminSearchService;
    this.ns = notificationStore;
  }

  data: SampleType[] = [];
  isLoading: boolean = false;

  sampleGet = async () => {
    try {
      this.isLoading = true;
      // const response = await this.adminSearchService.sampleFetch();
      // this.data = response;
      this.ns.addToast('success', 'Fetched data from AdminSearchStore', 'success');
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default AdminSearchStore;
