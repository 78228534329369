import { AxiosInstance } from 'axios';

export class MediaService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getMedia = async (id: string) => {
    const response = await this.axiosInstance.get(`/api/admin/media/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching media.');
  };

  deleteMedia = async (id: string) => {
    const response = await this.axiosInstance.delete(`/api/admin/media/${id}`);

    if (response.status !== 200 || response.data !== '') throw Error('There was an internal error while deleting media.');
  };

  getMediaInCurrentFolder = async (path: string) => {
    const response = await this.axiosInstance.get(`/api/admin/media?folderPath=/${path}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching media in the current folder.');
  };

  getDataSetFields = async (id) => {
    const response = await this.axiosInstance.get(`/api/v2/admin/substitution-data-rows/${id}/only-keys`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error getting data set fields.');
  };

  getSubstitutionFile = async (id) => {
    const response = await this.axiosInstance.get(`/api/v2/admin/substitution-data/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error getting substitution file.');
  };

  uploadSubstitutionFile = async (data) => {
    const response = await this.axiosInstance.post(`/api/v2/admin/substitution-data`, data);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error uploading substitution file.');
  };

  getImagesFromTheCurrentFolder = async (currentFolder) => {
    const response = await this.axiosInstance.get(`/api/Media/GetTMUImagesInFolder?folderName=${currentFolder}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error getting images from the current folder.');
  };
}

export default MediaService;
