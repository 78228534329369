import { AxiosInstance } from 'axios';

export class MasterEditorService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  deleteMaster = async (id) => {
    const response = await this.axiosInstance.delete(`/api/v2/admin/courses/${id}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while deleting master.');
  };

  updateNewSortOrder = async (courseId, direction) => {
    const response = await this.axiosInstance.post(`/api/v2/admin/courses/sort`, { "CourseId": courseId, "MoveUp": direction === 'up'}); // why direction === 'up'?
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error updating new sort order.');
  };

  getMastersList = async () => {
    const response = await this.axiosInstance.get(`/api/v2/admin/master`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while getting masters list.');
  };
}

export default MasterEditorService;
