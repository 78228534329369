import { useCallback, useEffect, useMemo, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { ClassesTableRows } from 'types/organization/organization';
import DeleteStudentsModal from 'components/Organization/Modals/DeleteStudentsModal/DeleteStudentsModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { TableFooter } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as TrashIcon } from 'images/icons/Trash.svg';
import { handleSearchStudent } from 'components/Organization/OrganizationSearchbar/SearchForStudents/search-helper';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const SeeStudents = observer(() => {
  const { t } = useTranslation();
  const ss = useStores().studentsStore;
  const cs = useStores().classesStore;
  const iss = useStores().inviteStudentsStore;

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOn, setIsModalOn] = useState(false);

  useEffect(() => {
    iss.isPendingInvitesTabActive = false;
    ss.isSeeStudentsTabActive = true;
  }, [iss, ss]);

  useEffect(() => {
    ss.filteredList = handleSearchStudent(ss.searchInput, ss.students);
  }, [ss, ss.searchInput]);

  useEffect(() => {
    ss.filteredList = ss.students.filter((student) => student.classes?.find((classItem) => classItem.id === cs.selectedClassId));
  }, [cs, cs.selectedClassId, ss]);

  const rows = useMemo((): ClassesTableRows[] => {
    let dynamicRows: ClassesTableRows[] = [];

    ss.filteredList.map((student) => {
      const initials = student.name
        .split(' ')
        .map((a) => a[0])
        .join('');

      const studentClasses = () => {
        if (!student.classes?.length) {
          return null;
        } else if (student.classes.length === 1) {
          return student.classes[0].name;
        } else {
          return `${student.classes[0].name} + ${student.classes.length - 1} more`;
        }
      };

      dynamicRows.push({
        id: student.id,
        initials: initials,
        name: student.name,
        classes: studentClasses(),
        email: student.email,
      });

      return dynamicRows;
    });

    return dynamicRows;
  }, [ss.filteredList]);

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelectedRowIds = rows.map((n) => n.id);
        setSelectedRowIds(newSelectedRowIds);
        return;
      }
      setSelectedRowIds([]);
    },
    [rows]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selectedRowIds.indexOf(id);
      let newSelectedRowIds: string[] = [];

      if (selectedIndex === -1) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, id);
      } else if (selectedIndex === 0) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(1));
      } else if (selectedIndex === selectedRowIds.length - 1) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(0, selectedIndex), selectedRowIds.slice(selectedIndex + 1));
      }

      setSelectedRowIds(newSelectedRowIds);
    },
    [selectedRowIds]
  );

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  return (
    <div className='tmu-table-wrapper'>
      <div className='table-subheading'>
        <strong>{t('Students', { ns: 'studentsPage', number: ss.filteredList.length })}</strong>
        <button className='styled-button secondary' onClick={() => setIsModalOn(true)} disabled={selectedRowIds.length < 1}>
          <TrashIcon />
          <span>{t('DeleteStudents', { ns: 'studentsPage' })}</span>
        </button>
      </div>
      <Table sx={{ minWidth: 750 }} aria-labelledby='studentsTable'>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < rows.length}
                checked={rows.length > 0 && selectedRowIds.length === rows.length}
                onChange={(e) => handleSelectAllClick(e)}
                inputProps={{
                  'aria-label': t('SelectAllStudents', { ns: 'studentsPage' }),
                }}
              />
            </TableCell>
            <TableCell sx={{ width: 50 }}></TableCell>
            <TableCell sx={{ width: 250 }}>{t('Name', { ns: 'studentsPage' })}</TableCell>
            <TableCell sx={{ width: 250 }}>{t('Classes', { ns: 'studentsPage' })}</TableCell>
            <TableCell sx={{ width: 250 }}>{t('Email', { ns: 'studentsPage' })}</TableCell>
            <TableCell sx={{ width: 50 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row, index) => {
            const isItemSelected = selectedRowIds.indexOf(row.id) !== -1;
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.id)}
                role='checkbox'
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='primary'
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </TableCell>
                <TableCell className='initials-cell'>
                  <div>{row.initials}</div>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.classes}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell className='buttons-cell'>
                  <button
                    className='row-button'
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRowIds([row.id]);
                      setIsModalOn(true);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newPage: number) => setPage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteStudentsModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} selected={selectedRowIds} />
    </div>
  );
});

export default SeeStudents;
