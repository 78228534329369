import '../Policies.scss';

import Paragraph from 'components/TextComps/Paragraph';
import Subtitle from 'components/TextComps/Subtitle';
import { observer } from 'mobx-react-lite';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicyPage = observer(() => {
  const { t } = useTranslation();
  useSetPageTitle({ title: t('PrivacyPolicy', { ns: 'general' }), subtitle: '' });

  return (
    <div className='policies-container'>
      <div className='policies-section'>
        <Subtitle>{t('TrackmanPrivacyNotice', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('ThisPrivacyNoticeExplains', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('HowToContactUs', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('IfYouHaveAnyQuestionsAboutTheUse', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('SituationsWherePersonalDataMayBeCollected', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('TrackmanCollectsCertainPersonalData', { ns: 'privacyPolicy' })}</Paragraph>
        <h4>{t('WebsiteBrowsing', { ns: 'privacyPolicy' })}</h4>
        <Paragraph>{t('WeCollectInformationOfIP', { ns: 'privacyPolicy' })}</Paragraph>
        <h4>{t('EnquiriesThroughTheTrackmanCRMSystem', { ns: 'privacyPolicy' })}</h4>
        <Paragraph>{t('IfYouContactTrackmansCRMSystem', { ns: 'privacyPolicy' })}</Paragraph>
        <h4>{t('PurchaseOfATrackmanProduct', { ns: 'privacyPolicy' })}</h4>
        <Paragraph>{t('WhenYouPurchaseATrackmanProduct', { ns: 'privacyPolicy' })}</Paragraph>
        <h4>{t('OpeningOfTrackmanAccount', { ns: 'privacyPolicy' })}</h4>
        <Paragraph>{t('WhenYouCreateATrackmanAccountAndBecome', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('TrackmanProfilesCanBeEither', { ns: 'privacyPolicy' })}</Paragraph>
        <h4>{t('TournamentsAndCompetitions', { ns: 'privacyPolicy' })}</h4>
        <Paragraph>{t('IfYouParticipateInAnyTournaments', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('UseOfProductsAndServices', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('IfYouUseYourTrackmanProductToCollectData', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('IfTheStandardizedTrackmanCombineTest', { ns: 'privacyPolicy' })}</Paragraph>
        <h4>{t('CollectionOfFunctionalData', { ns: 'privacyPolicy' })}</h4>
        <Paragraph>{t('InAdditionToTheAbove', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('PurposeOfProcessingPersonalInformation', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('TheInformationWeCollectFromYou', { ns: 'privacyPolicy' })}</Paragraph>
        <ul>
          <li>{t('ToProvideYouWithDetailedAndComprehensiveData', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToPersonalizeYourExperience', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToShareYourAccountInformation', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToContinuoslyImproveTheTrackmanEquipment', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToFactilitateTrackmanBusinessOperations', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToEnsureASafeAndSecureProduct', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToImproveOurWebsites', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToImproveCustomerSupport', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToProcessOrdersAndTransactions', { ns: 'privacyPolicy' })}</li>
          <li>{t('ToFacilitateSharingAndDisclosure', { ns: 'privacyPolicy' })}</li>
          <li>{t('ForDocumentationPurposes', { ns: 'privacyPolicy' })}</li>
          <li>{t('ForMarketingAndCommunicationPurposes', { ns: 'privacyPolicy' })}</li>
        </ul>
        <Paragraph>{t('TheEmailAddressYouProvideForOrderProcessing', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('LawfulnessOfProcessing', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('InGeneralOurProcessingOfYourPersonalDataIsBasedOn', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('ProcessingOfYourPersonalDataMayPotentially', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('InformationSharing', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('YourInformationWhetherIsPublicOrPrivate', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('NotwithstandingTheAboveYourNameAndPerformance', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('WeOnlyShareInformationWithPartners', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('DetailsOfTransfersToThirdCountry', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('YourPersonalDataMayBeStored', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('RetentionPeriods', { ns: 'privacyPolicy' })}</Subtitle>
        <h4>{t('CustomerData', { ns: 'privacyPolicy' })}</h4>
        <Paragraph>{t('CustomerDataIncludingPerformanceData', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('PerformanceDataAndFunctionalData', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('CRMDataIsRetained', { ns: 'privacyPolicy' })}</Paragraph>
        <h4>{t('NoncustomerData', { ns: 'privacyPolicy' })}</h4>
        <Paragraph>{t('NameEmailAddressAndOrTelephoneNo', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('NotwithstandingTheAboveTrackmanMayRetain', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('UseOfCookies', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('WeUseCookies', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('YourRights', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('YouHaveCertainRightsUnderTheApplicableData', { ns: 'privacyPolicy' })}</Paragraph>
        <ul>
          <li>{t('TheRightToBeInformedViaPrivacy', { ns: 'privacyPolicy' })}</li>
          <li>{t('TheRightOfAccessToAnyPersonalInformationWeHold', { ns: 'privacyPolicy' })}</li>
          <li>{t('TheRightofRecertification', { ns: 'privacyPolicy' })}</li>
          <li>{t('TheRightToErasure', { ns: 'privacyPolicy' })}</li>
          <li>{t('TheRightToRestrictProcessing', { ns: 'privacyPolicy' })}</li>
          <li>{t('TheRightToWithdrawYourConsent', { ns: 'privacyPolicy' })}</li>
          <li>{t('TheRightToDataPortability', { ns: 'privacyPolicy' })}</li>
          <li>{t('TheRightToObjectToProcessing', { ns: 'privacyPolicy' })}</li>
        </ul>
        <Paragraph>{t('YouCanReadMoreAboutYourRights', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('QuestionsAndComplaints', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('IfYouHaveAnyQuestionsOrWishToMakeAComplaint', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>{t('IfYouAreStillDissatisfied', { ns: 'privacyPolicy' })}</Paragraph>
        <Paragraph>
          <>Datatilsynet Borgergade 28, 5 DK-1300 Copenhagen K Tel: 3319 3200 Fax: 3319 3218 E-mail: dt@datatilsynet.dk</>
        </Paragraph>
      </div>
      <div className='policies-section'>
        <Subtitle>{t('Changes', { ns: 'privacyPolicy' })}</Subtitle>
        <Paragraph>{t('IfThisPrivacyNoticeChangesInAnyWay', { ns: 'privacyPolicy' })}</Paragraph>
      </div>
    </div>
  );
});

export default PrivacyPolicyPage;
