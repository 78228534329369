import { AvailableCertificates } from 'types/certificates';
import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';

export class CertificateService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  certificateLevel = async (level: string) => {
    const response = await this.axiosInstance.get(`/api/certificate-pdf/${level}`, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
    });

    if (response && response.data && !response.data.isError) {
      return response.data;
    }
    throw Error(`There was an internal error while getting certificate ${level}.`);
  };

  checkAvailableCertificates = async () => {
    const response = await this.axiosInstance.get<Result<AvailableCertificates>>('/api/certificate-pdf/check-available-certificates');

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }

    throw Error('There was an internal error while getting available certificates.');
  };
}

export default CertificateService;
