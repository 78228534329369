import './PlayerInformationTab.scss';

import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@trackman/web-shared-components';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/Error.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ReactComponent as MapMarkerIcon } from 'images/icons/MapMarker.svg';
import Modal from '@mui/material/Modal';
import { ReactComponent as PadlockIcon } from 'images/icons/PadlockDark.svg';
import { ReactComponent as PencilIcon } from 'images/icons/Pencil.svg';
import { ReactComponent as ProfileIcon } from 'images/icons/Profile.svg';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import Separator from 'components/Separator/Separator';
import { TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { trimTooManySpaces } from '../../../../utilities/inputValidation';
import { useState } from 'react';
import { useStores } from 'index';

export const PlayerInformationTab = observer(() => {
  const ps = useStores().profileStore;
  const cp = useStores().passwordStore;
  const { t } = useTranslation();
  const [isInfoValid, setIsInfoValid] = useState(true);
  const [isNewPasswordTheSame, setIsNewPasswordTheSame] = useState(true);
  const [isModalOn, setIsModalOn] = useState(false);

  return (
    <>
      <div className='profile-section personal-info-section'>
        <div className='profile-inputs-column'>
          <div className='profile-inputs-column-title'>
            <ProfileIcon />
            {t('PersonalInformation', { ns: 'editProfile' })}
          </div>
          <ProfileInput
            label={t('PlayerName', { ns: 'editProfile' })}
            value={ps.profileData.firstName ?? ''}
            required={true}
            error={ps.isLoaded && !ps.profileData.firstName}
            onChange={(newValue) => {
              ps.profileData.firstName = trimTooManySpaces(newValue);
              if (!newValue.replace(/\s/g, '').length) ps.profileData.firstName = '';
              setIsInfoValid(!!ps.profileData.firstName);
            }}
          />
          <ProfileInput
            label={t('PlayerSurname', { ns: 'editProfile' })}
            value={ps.profileData.lastName ?? ''}
            required={true}
            error={ps.isLoaded && !ps.profileData.lastName}
            onChange={(newValue) => {
              ps.profileData.lastName = trimTooManySpaces(newValue);
              if (!newValue.replace(/\s/g, '').length) ps.profileData.lastName = '';
              setIsInfoValid(!!ps.profileData.lastName);
            }}
          />
          <ProfileInput
            label={t('Gender', { ns: 'editProfile' })}
            value={ps.profileData.gender ?? ''}
            onChange={(newValue) => (ps.profileData.gender = newValue)}
            selectOptions={[
              { label: t('PleaseSelect', { ns: 'editProfile' }), value: 'Undefined' },
              { label: t('Male', { ns: 'editProfile' }), value: 'Male' },
              { label: t('Female', { ns: 'editProfile' }), value: 'Female' },
            ]}
          />
          <ProfileInput
            label={t('MobilePhone', { ns: 'editProfile' })}
            value={ps.profileData.phone ?? ''}
            onChange={(newValue) => {
              ps.profileData.phone = trimTooManySpaces(newValue);
              if (!newValue.replace(/\s/g, '').length) ps.profileData.phone = '';
              setIsInfoValid(!!ps.profileData.phone);
            }}
          />
          <div className='newsletter-checkbox'>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    ps.profileData.acceptedEmails = e.target.checked;
                  }}
                  checked={ps.profileData.acceptedEmails}
                />
              }
              label={
                <p>
                  <Trans i18nKey='ReceiveOffersAndPromotionsFromTrackman' ns='editProfile'>
                    Receive offers and promotions from Trackman&nbsp;
                    <span>(CVR.DK27352677)</span>
                  </Trans>
                </p>
              }
            />
          </div>
        </div>
        <div className='profile-inputs-column'>
          <div className='profile-inputs-column-title'>
            <MapMarkerIcon />
            {t('AddressInformation', { ns: 'editProfile' })}
          </div>
          <ProfileInput
            label={t('StreetName1', { ns: 'editProfile' })}
            value={ps.profileData.address.street1 ?? ''}
            onChange={(newValue) => {
              ps.profileData.address.street1 = trimTooManySpaces(newValue);
            }}
          />
          <ProfileInput
            label={t('StreetName2', { ns: 'editProfile' })}
            value={ps.profileData.address.street2 ?? ''}
            onChange={(newValue) => {
              ps.profileData.address.street2 = trimTooManySpaces(newValue);
            }}
          />
          <ProfileInput
            label={t('PostalCode', { ns: 'editProfile' })}
            value={ps.profileData.address.postalCode ?? ''}
            onChange={(newValue) => {
              ps.profileData.address.postalCode = trimTooManySpaces(newValue);
            }}
          />
          <ProfileInput
            label={t('City', { ns: 'editProfile' })}
            value={ps.profileData.address.city ?? ''}
            onChange={(newValue) => {
              ps.profileData.address.city = trimTooManySpaces(newValue);
            }}
          />
          <ProfileInput
            label={t('Country', { ns: 'editProfile' })}
            value={ps.profileData.address.country ?? ''}
            required={true}
            error={ps.isLoaded && !ps.profileData.address.country}
            onChange={(newValue) => {
              ps.profileData.address.country = newValue;
              setIsInfoValid(!!ps.profileData.address.country.trim());
            }}
            selectOptions={ps.signUpLists.Countries.map((country) => {
              return {
                label: country.name === ' ' ? t('PleaseSelectACountry', { ns: 'editProfile' }) : country.name,
                value: country.name,
              };
            })}
          />
          {ps.profileData.address.country === 'United States' && (
            <ProfileInput
              label={t('State', { ns: 'editProfile' })}
              value={ps.profileData.address.state ?? ' ' ?? ''}
              onChange={(newValue) => {
                ps.profileData.address.state = newValue;
              }}
              selectOptions={ps.signUpLists.States.map((state) => {
                return {
                  label: state === ' ' ? t('PleaseSelectAState', { ns: 'editProfile' }) : state,
                  value: state,
                };
              })}
            />
          )}
          <div className='button-wrapper'>
            <Button
              label={t('SaveChanges', { ns: 'editProfile' })}
              onClick={() => {
                ps.saveProfile();
              }}
              intent='primary'
              isLoading={ps.isSavingProfile}
              disabled={ps.isLoading || ps.isSavingProfile || !isInfoValid}
              placeholder={undefined}
              className='styled-button primary'
            />
            {!isInfoValid && (
              <p className='error-info'>
                <ErrorIcon />
                <span>{t('ProfileInformationIsMissing', { ns: 'editProfile' })}</span>
              </p>
            )}
          </div>
        </div>
      </div>
      <Separator />
      <div className='profile-section password-section'>
        <div className='profile-inputs-column'>
          <div className='profile-inputs-column-title'>
            <PadlockIcon />
            {t('Password', { ns: 'editProfile' })}
          </div>
          <TextField
            label={t('CurrentPassword', { ns: 'editProfile' })}
            type='password'
            inputProps={{ readOnly: true }}
            value='*************'
          />
          <Button
            className='styled-button'
            label={t('ChangePassword', { ns: 'editProfile' })}
            onClick={() => setIsModalOn(true)}
            intent='tertiary'
            disabled={ps.isLoading}
            placeholder={undefined}
          />

          <Modal open={isModalOn} onClose={() => setIsModalOn(false)} className='change-password-modal profile-modal'>
            <div className='modal-container'>
              <div className='modal-header'>
                <h2 className='modal-title'>
                  <PencilIcon />
                  {t('EditPassword', { ns: 'editProfile' })}
                </h2>
                <CloseIcon onClick={() => setIsModalOn(false)} />
              </div>
              <div className='modal-body'>
                <h3 className='section-subtitle'>{t('Password', { ns: 'editProfile' })}</h3>
                <ProfileInput
                  label={t('OldPassword', { ns: 'editProfile' })}
                  value={cp.oldPassword ?? ''}
                  type='password'
                  onChange={(newValue) => (cp.oldPassword = newValue)}
                />
                <ProfileInput
                  label={t('NewPassword', { ns: 'editProfile' })}
                  value={cp.newPassword ?? ''}
                  type='password'
                  onChange={(newValue) => (cp.newPassword = newValue)}
                />
                <ProfileInput
                  label={t('ConfirmNewPassword', { ns: 'editProfile' })}
                  value={cp.confirmPassword ?? ''}
                  type='password'
                  error={!isNewPasswordTheSame}
                  errorMsg={t('ThisFieldMustMatchTheNewPassword', { ns: 'editProfile' })}
                  onChange={(newValue) => {
                    setIsNewPasswordTheSame(cp.newPassword === newValue);
                    cp.confirmPassword = newValue;
                  }}
                />
              </div>
              <div className='modal-footer'>
                <button className='styled-button secondary' onClick={() => setIsModalOn(false)}>
                  {t('Cancel', { ns: 'general' })}
                </button>
                <button
                  className='styled-button primary'
                  onClick={() => {
                    cp.changePassword();
                    setIsModalOn(false);
                  }}
                >
                  {t('SaveChanges', { ns: 'editProfile' })}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
});

export default PlayerInformationTab;
