import './SubscriptionsTab.scss';

import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as BellIcon } from 'images/icons/Bell.svg';
import { ReactComponent as RestoreIcon } from 'images/icons/Restore.svg';
import Separator from 'components/Separator/Separator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { formatDate } from 'utilities/formatDate';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

export const SubscriptionsTab = observer(() => {
  const bs = useStores().billingStore;
  const is = useStores().invoicesStore;
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  useEffect(() => {
    bs.getSubscriptions();
    is.getInvoices();
  }, [bs, bs.subscriptionData.length, is, is.invoicesData.length]);

  const rows = useMemo(() => {
    return is.invoicesData.map((invoice) => {
      return {
        id: invoice.uuid,
        name: invoice.line_items[0].description,
        invoiceNumber: invoice.transactions[0] ? Number(invoice.transactions[0].invoice.number) : null,
        paymentDate: formatDate(invoice.created_at),
        discount: invoice.discount === 0 ? '-' : `$${invoice.discount}`,
        price: `$${invoice.total}`,
        endDate: formatDate(invoice.line_items[0].end_date),
      };
    });
  }, [is.invoicesData]);

  return (
    <>
      <div className='profile-section subscription-section'>
        <div className='profile-inputs-column-title'>
          <BellIcon />
          {t('CurrentSubscriptions', { ns: 'billingInformation' })}
        </div>
        {is.invoicesErrorCode || (!is.isInvoicesLoading && !bs.subscriptionData.length) ? (
          <div className='no-active-subscriptions'>{t('YouHaveNoSubscriptions', { ns: 'billingInformation' })}</div>
        ) : (
          bs.subscriptionData.map((subscription, i) => {
            const formattedDate = formatDate(subscription.currentPeriodEnds);
            const expired = Date.now() > Date.parse(subscription.currentPeriodEnds);

            return (
              <div className='plan-info' key={subscription.id}>
                {!(expired || subscription.cancelled) ? (
                  <h3 className='section-subtitle'>{t('YourCurrentPlan', { ns: 'billingInformation' })}</h3>
                ) : (
                  <h3 className='section-subtitle'>{t('YourPlan', { ns: 'billingInformation' })}</h3>
                )}
                <p>
                  <strong className='plan-name'>{subscription.planName}</strong>
                  {expired ? (
                    <strong className='expiry-info expired'>
                      {t('Expired', { ns: 'billingInformation' })}&nbsp;
                      {formattedDate}
                    </strong>
                  ) : subscription.cancelled ? (
                    <strong className='expiry-info cancelled'>{t('Cancelled', { ns: 'billingInformation' })}</strong>
                  ) : (
                    <strong className='expiry-info valid'>
                      {t('ValidUntil', { ns: 'billingInformation' })}&nbsp;
                      {formattedDate}
                    </strong>
                  )}
                </p>

                <Separator />
                <h3 className='section-subtitle'>{t('Rate', { ns: 'billingInformation' })}</h3>
                <p>
                  <strong>${subscription.annualRate}</strong>
                </p>
                <Separator />
                <h3 className='section-subtitle'>{t('Price', { ns: 'billingInformation' })}</h3>
                <p>
                  <strong>${subscription.price}</strong>
                </p>
                <Separator />
                {!expired && !subscription.cancelled ? (
                  <>
                    <strong className='next-billing'>Next billing date: {formattedDate}</strong>
                    <p className='subscription-contact'>
                      {t('CancelSubscriptionInformation', { ns: 'billingInformation' })}&nbsp;
                      <a href='mailto:support@trackman.com'>support@Trackman.com</a>
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
          })
        )}
        <p className='subscription-contact'>
          <Trans i18nKey='ContactWithBillingAndPaymentRelatedQuestions' ns='billingInformation'>
            Contact <a href='mailto:support@trackman.com'>support@Trackman.com</a> with Billing & Payment related questions
          </Trans>
        </p>
      </div>
      {is.invoicesData.length ? (
        <div className='profile-section billing-history-section'>
          <div className='profile-inputs-column-title'>
            <RestoreIcon />
            {t('BillingHistory', { ns: 'billingInformation' })}
          </div>
          <div className='tmu-table-wrapper'>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>{t('PlanName', { ns: 'billingInformation' })}</TableCell>
                  <TableCell align='right'>{t('PaymentDate', { ns: 'billingInformation' })}</TableCell>
                  <TableCell align='right'>{t('Discount', { ns: 'billingInformation' })}</TableCell>
                  <TableCell align='right'>{t('Price', { ns: 'billingInformation' })}</TableCell>
                  <TableCell align='right'>{t('Expiry', { ns: 'billingInformation' })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      <div>
                        {row.name}{' '}
                        {row.invoiceNumber && (
                          <span className='link' onClick={() => row.invoiceNumber !== null && is.downloadPdfInvoice(row.invoiceNumber)}>
                            {t('Receipt', { ns: 'billingInformation' })}
                          </span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align='right'>{row.paymentDate}</TableCell>
                    <TableCell align='right'>{row.discount}</TableCell>
                    <TableCell align='right'>{row.price}</TableCell>
                    <TableCell align='right'>{row.endDate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={5}
                    count={rows.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={t('RowsPerPage', { ns: 'general' })}
                    slotProps={{
                      select: {
                        'aria-label': t('RowsPerPage', { ns: 'general' }),
                      },
                    }}
                    onPageChange={(e, newPage: number) => setPage(newPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
      ) : null}
    </>
  );
});

export default SubscriptionsTab;
