import { AxiosInstance } from 'axios';

export class PasswordService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  changePassword = async (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    const response = await this.axiosInstance.post(`/api/Account/ChangePassword`, {
      OldPassword: oldPassword,
      Password: newPassword,
      ConfirmPassword: confirmNewPassword,
    });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while changing the password.');
  };
}

export default PasswordService;
