import './SwingDirectionChartComps.scss';

import { CalculatorOptions } from 'types/calculator/CalculatorOptions';
import { ClubType } from 'components/FullCalculator/constants/selectOptions';
import swingdirection6IronImg from './images/swingdirection-6iron.png';
import swingdirectionDriverImg from './images/swingdirection-driver.png';
import swingdirectionPitchingWedgeImg from './images/swingdirection-pitching-wedge.png';
import useClubType from 'components/FullCalculator/hooks/useClubType';

type Props = {
  swingDirectionAngle: number;
  radiusInPx: number;
  calcOptions: CalculatorOptions;
};

const swingImgByType: { [key in ClubType]: string } = {
  Driver: swingdirectionDriverImg,
  '6Iron': swingdirection6IronImg,
  PitchingWedge: swingdirectionPitchingWedgeImg,
};

const SwingDirectionImgAndArc = ({ swingDirectionAngle, radiusInPx, calcOptions }: Props) => {
  const { clubType } = useClubType(calcOptions);

  const angle = Math.abs(swingDirectionAngle);
  const circumference = 2 * Math.PI * radiusInPx;
  const strokeDasharray = (angle / 360) * circumference;

  return (
    <>
      <svg
        height='100%'
        width='100%'
        style={{
          transform: swingDirectionAngle < 0 ? 'scaleY(-1)' : '',
        }}
        className='swing-direction-arc'
      >
        <circle
          stroke='orange'
          strokeWidth='5'
          fill='none'
          cx='0'
          cy='0'
          r={radiusInPx}
          strokeDasharray={`${strokeDasharray},${circumference - strokeDasharray}`} // svg trick to draw semicircle, comma separated values must add up to circumference
        />
      </svg>
      <img
        src={swingImgByType[clubType]}
        style={{
          transform: `translateX(-50%) rotate(${swingDirectionAngle}deg)`,
        }}
        className='swing-direction-img'
      />
    </>
  );
};

export default SwingDirectionImgAndArc;
