export class ConfigData {
  logoutUrl: string = '';
  myAccountUrl: string = '';
}

export interface IConfigurationService {
  getConfigs(): Promise<ConfigData>;
  getWindowOrigin(): string;
}

export class ConfigurationService implements IConfigurationService {
  private static configs: ConfigData;
  async getConfigs() {
    if (ConfigurationService.configs) return Promise.resolve(ConfigurationService.configs);

    try {
      const response = await fetch(`${this.getWindowOrigin()}/Home/Config`);
      if (response.redirected) window.location.assign(response.url);
      const jsonConfig = await response.json();
      ConfigurationService.configs = jsonConfig;
      return ConfigurationService.configs;
    } catch (ex) {
      return { logoutUrl: '', myAccountUrl: '' };
    }
  }

  getWindowOrigin(): string {
    if (process.env.NODE_ENV === 'development') {
      return 'https://localhost:' + process.env.REACT_APP_DEV_PORT;
    } else if (process.env.NODE_ENV === 'production') {
      if (!window.location.origin) {
        (window.location as any).origin =
          window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      }
    }
    return window.location.origin;
  }
}
