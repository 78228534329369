import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import Paragraph from 'components/TextComps/Paragraph';
import { StudyRoomLessonModel } from 'types/admin/studyroomLessons';
import { TextArea } from '@trackman/web-shared-components';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel | StudyRoomLessonModel;
};

export const DescriptionInputs = observer(({ dataItem }: Props) => {
  return (
    <div className='admin-edit-section'>
      <h2>Description</h2>
      <div className='inputs-container'>
        {dataItem.descriptions.map((description) => {
          return (
            <div key={description.countryCode} className='input-wrapper'>
              <Paragraph>
                <>{getLanguageText(description.countryCode)}</>
              </Paragraph>
              <TextArea
                label={`${getLanguageText(description.countryCode)} description`}
                value={description.text ?? ''}
                onChange={(e) => (description.text = e.target.value)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});
