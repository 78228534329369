import { configure, makeAutoObservable } from 'mobx';

import { AvailableCertificates } from 'types/certificates';
import CertificateService from '../../services/UserAccount/CertificateService';
import { NotificationsStore } from '../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class CertificateStore {
  private certificateService: CertificateService;
  private ns: NotificationsStore;

  constructor(certificateService: CertificateService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.certificateService = certificateService;
    this.ns = notificationStore;
  }

  isLoading: string = '';
  isAvailableCertsLoading: boolean = true;
  availableCertificates: AvailableCertificates = { 'tmu-Level1': false, 'tmu-Level2': false, 'tmu-Master': false };

  downloadPdfCertificate = async (level: string) => {
    try {
      this.isLoading = level;

      await this.certificateService.certificateLevel(level).then((response) => {
        const href = window.URL.createObjectURL(response);

        const anchorElement = document.createElement('a');

        anchorElement.href = href;
        anchorElement.download = `Trackman-Certificate-${level}`;
        anchorElement.style.display = 'none';

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = '';
  };

  checkAvailableCertificates = async () => {
    try {
      const response = await this.certificateService.checkAvailableCertificates();

      this.availableCertificates = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isAvailableCertsLoading = false;
  };
}

export default CertificateStore;
