import { ApplicationUserRights } from 'stores/ApplicationUserRightsStore';
import { ConfigurationService } from './ConfigurationService';
import { auth } from 'stores/AuthorizationStore';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';

interface TokenResponse {
  isAuthenticated: boolean;
  accessToken: string;
  expiresAt: string;
  idToken: string;
}
export class AuthenticationService {
  private configService: ConfigurationService;
  private pathAccountMe: string = 'account/me';
  private pathAccountPermissions: string = 'account/privileges';
  private pathLogin: string = 'account/login';
  private pathIsUserLoggedIn: string = 'account/is-user-logged';
  private isLoggedInStorageKey: string = 'isLoggedIn';
  private isLoggedIn: boolean = true;
  private authData: TokenResponse | null = null;

  constructor() {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.configService = new ConfigurationService();
  }

  isUserLoggedIn: boolean = false;
  isLoadingUserLoggedIn: boolean = true; // initialized as true to display loading immediately
  isLoggingIn: boolean = false;

  // these are methods for new TMU setup

  checkIfUserIsLoggedIn = async () => {
    this.isLoadingUserLoggedIn = true;
    try {
      const selfUrl = this.configService !== null && this.configService.getWindowOrigin();
      const requestURL = `${selfUrl}/${this.pathIsUserLoggedIn}`;
      const response = await axios.get(requestURL);
      this.isUserLoggedIn = response.data;
    } catch {
      this.isUserLoggedIn = false;
    }
    this.isLoadingUserLoggedIn = false;
  };

  logIn = async () => {
    this.isLoggingIn = true;
    window.location.assign(`${this.configService.getWindowOrigin()}/${this.pathLogin}`); // this works with the current backend configuration only
    this.isLoggingIn = false;
  };

  // the methods below come from AppTemplate and can be removed once the TMU Auth setup is complete

  initialize = async () => {
    await this.getToken();
    //await this.getPermissions();

    window.addEventListener('storage', this.onStorageChanged);
  };

  getAuthData() {
    return this.authData;
  }

  getToken = async () => {
    if (!this.isLoggedIn) {
      // if we were already logged out once, don't allow to log in again in the same instance.
      // User needs to reload page to log in again.
      return;
    }

    try {
      const selfUrl = this.configService !== null && this.configService.getWindowOrigin();
      const requestURL = `${selfUrl}/${this.pathAccountMe}`;
      const response = await axios.get(requestURL, {
        maxRedirects: 0,
        withCredentials: true,
      });

      if (response.request.responseURL !== requestURL) {
        // Since this endpoint redirects to login page when unauthorized and we cannot prevent redirection in browser JS,
        // we detect it by comparing URL of request and response.

        // Redirected means unauthorized.
        this.setAuthInfo(null);
        return;
      }

      // Authorized.
      this.setAuthInfo(response.data);
    } catch (error) {
      this.setAuthInfo(null);
    }
  };

  hasToken = (): boolean => {
    return (
      this.authData !== null &&
      Date.parse(this.authData.expiresAt) - Date.now() > 1000 &&
      window.localStorage.getItem(this.isLoggedInStorageKey) !== null &&
      window.localStorage.getItem(this.isLoggedInStorageKey) !== 'false'
    );
  };

  setAuthInfo = (authInfo: TokenResponse | null) => {
    this.setIsLoggedIn(authInfo !== null);
    this.authData = authInfo;
  };

  getPermissions = async () => {
    const selfUrl = this.configService.getWindowOrigin();
    const response = await axios.get(`${selfUrl}/${this.pathAccountPermissions}`, { withCredentials: true });

    if (response.status === 200) {
      ApplicationUserRights.setData(response.data);
    }
  };

  private onStorageChanged = (e: StorageEvent) => {
    if (e.key !== this.isLoggedInStorageKey) {
      return;
    }

    // refresh value when storage flag changes
    this.getToken();
  };

  private setIsLoggedIn(value: boolean) {
    if (value) {
      window.localStorage.setItem(this.isLoggedInStorageKey, 'true');
    } else {
      window.localStorage.removeItem(this.isLoggedInStorageKey);
    }

    this.isLoggedIn = value;
    auth.setIsLoggedIn(value);
  }
}
