import { AxiosInstance } from 'axios';
import { LessonModel } from 'types/lessons';
import { Result } from 'services/Result';

export class LessonService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getUserProgress = async () => {
    const response = await this.axiosInstance.get(`/api/v2/progresses/HasUserProgress`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching user progress.');
  };

  startLesson = async (lessonId: string, courseId: string) => {
    const response = await this.axiosInstance.get<Result<LessonModel>>(`/api/lessons/${lessonId}?courseId=${courseId}`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching starting lesson.');
  };
}

export default LessonService;
