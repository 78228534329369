import { useEffect, useMemo, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from 'images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from 'images/icons/ArrowUp.svg';
import LearningMaterialsModal from '../Modals/LearningMaterialsModal/LearningMaterialsModal';
import { ReactComponent as MinusIcon } from 'images/icons/MinusInCircle.svg';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/Plus.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { moveLessonId } from 'utilities/sortLessons';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type RowsDisplay = {
  id: string;
  name: string;
  category: string;
};

export const LearningMaterialsTable = observer(() => {
  const [isModalOn, setIsModalOn] = useState(false);
  const lms = useStores().learningMaterialStore;
  const sll = useStores().studyroomLessonEditorStore;

  useEffect(() => {
    lms.getLearningMaterials();
  }, [lms]);

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      sll.studyLesson.learningMaterialIds
        .map((id) => {
          const studyLessonLearningMaterial = lms.learningMaterialsList.find((learningMaterialItem) => learningMaterialItem.id === id);

          if (studyLessonLearningMaterial === undefined) return;

          return {
            id: id,
            name: studyLessonLearningMaterial.titles.find((title) => title.countryCode === 'en-US')?.text,
            category: studyLessonLearningMaterial.kind,
          };
        })
        .filter((row) => row !== undefined) as RowsDisplay[],
    [JSON.stringify(sll.studyLesson.learningMaterialIds), JSON.stringify(lms.learningMaterialsList)]
  );

  return (
    <div className='tmu-table-wrapper admin-edit-section'>
      <div className='table-subheading'>
        <strong className='prerequisites-title'>Learning material</strong>
        <button onClick={() => setIsModalOn(true)} className='add-button styled-button primary'>
          <PlusIcon />
          <span>Choose material</span>
        </button>
      </div>
      <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsDisplay.length && rowsDisplay[0] !== undefined ? (
            rowsDisplay.map((row, index) => {
              return (
                <TableRow hover key={row.id} sx={{ cursor: 'pointer' }}>
                  <TableCell>
                    <button
                      className='row-button'
                      onClick={() =>
                        (sll.studyLesson.learningMaterialIds = sll.studyLesson.learningMaterialIds?.filter((id) => id !== row.id))
                      }
                    >
                      <MinusIcon />
                    </button>
                    {row.name.toLowerCase()}
                  </TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>
                    <button
                      className='row-button'
                      style={index === 0 ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                      onClick={() => moveLessonId(sll.studyLesson.learningMaterialIds, lms.learningMaterialsList, row.id, -1)}
                    >
                      <ArrowUpIcon />
                    </button>
                    <button
                      className='row-button'
                      style={index === rowsDisplay.length - 1 ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                      onClick={() => moveLessonId(sll.studyLesson.learningMaterialIds, lms.learningMaterialsList, row.id, 1)}
                    >
                      <ArrowDownIcon />
                    </button>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <Paragraph>
                  <>No learning materials added</>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <LearningMaterialsModal isModalOn={isModalOn} setIsModalOn={setIsModalOn} />
    </div>
  );
});

export default LearningMaterialsTable;
