import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import Paragraph from 'components/TextComps/Paragraph';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { StudyRoomLessonModel } from 'types/admin/studyroomLessons';
import { getLanguageText } from 'utilities/languages';
import { observer } from 'mobx-react-lite';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel | StudyRoomLessonModel;
};

export const TitleInputs = observer(({ dataItem }: Props) => {
  return (
    <div className='admin-edit-section'>
      <h2>Title</h2>
      <div className='inputs-container'>
        {dataItem.titles.map((title) => {
          return (
            <div key={title.countryCode} className='input-wrapper'>
              <Paragraph>
                <>{getLanguageText(title.countryCode)}</>
              </Paragraph>
              <ProfileInput
                label={`${getLanguageText(title.countryCode)} title`}
                value={title.text ?? ''}
                onChange={(newValue) => (title.text = newValue)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default TitleInputs;
