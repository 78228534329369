import { intersection } from 'lodash';
import { makeAutoObservable } from 'mobx';

interface IUsersPermissionsModel {
  permissions: string[];
}

class ApplicationUserRightsStore {
  permissions: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public IsAllowed(requiredPermissions: string[]): boolean {
    const intsec = intersection(this.permissions, requiredPermissions);
    if (intsec.length === requiredPermissions.length) {
      return true;
    }
    return false;
  }

  setData(data: IUsersPermissionsModel) {
    this.permissions = data.permissions;
  }
}

export const ApplicationUserRights = new ApplicationUserRightsStore();
