import { configure, makeAutoObservable } from 'mobx';

import MastersService from '../../services/Masters/MastersService';
import { NotificationsStore } from '../NotificationsStore';
import { PartnerOrMaster } from 'types/partner';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class MastersStore {
  private mastersService: MastersService;
  private ns: NotificationsStore;

  constructor(mastersService: MastersService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.mastersService = mastersService;
    this.ns = notificationStore;
  }

  masters: PartnerOrMaster[] | number[] = [0, 1, 2, 3, 4, 5, 6, 7]; // generate 8 skeletons until Masters load
  isLoading: boolean = false;

  getMasters = async () => {
    try {
      this.isLoading = true;
      const response = await this.mastersService.getMasters();
      this.masters = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default MastersStore;
