import { LearningMaterialModel, LearningMaterialTypeList, LearningMaterialsList } from 'types/admin/learningMaterials';
import { configure, makeAutoObservable } from 'mobx';

import LearningMaterialService from '../../../services/Admin/Editors/LearningMaterialService';
import { NotificationsStore } from '../../NotificationsStore';
import { getErrorMessage } from 'utilities/ErrorHelper';

configure({ enforceActions: 'never' });

export class LearningMaterialStore {
  private learningMaterialService: LearningMaterialService;
  private ns: NotificationsStore;

  constructor(learningMaterialService: LearningMaterialService, notificationStore: NotificationsStore) {
    makeAutoObservable(this); //This line will automatically decorate each store property with 'observable' and each method with 'action'.
    this.learningMaterialService = learningMaterialService;
    this.ns = notificationStore;
  }

  learningMaterialsList: LearningMaterialsList = [];
  cleanLearningMaterial: LearningMaterialModel = {
    id: '',
    titles: [{ countryCode: 'en-US', text: '' }],
    descriptions: [{ countryCode: 'en-US', text: '' }],
    languages: ['en-US'],
    medias: [{ countryCode: 'en-US', mediaId: '' }],
    kind: '',
    youTubeKey: '',
    calculatorKind: '',
    calculatorOptions: '',
    legacyId: '',
  };
  learningMaterial = { ...this.cleanLearningMaterial };
  selectedMaterialId: string = '';
  filteredList: LearningMaterialsList = [];
  learningMaterialTypes: LearningMaterialTypeList = [];

  isLoading: boolean = false;

  getLearningMaterials = async () => {
    try {
      this.isLoading = true;
      const response = await this.learningMaterialService.getLearningMaterials();
      this.learningMaterialsList = response;
      this.filteredList = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getLearningMaterial = async () => {
    try {
      this.isLoading = true;
      const response = await this.learningMaterialService.getLearningMaterial(this.selectedMaterialId);
      this.learningMaterial = response;
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  deleteLearningMaterialAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.learningMaterialService.deleteLearningMaterial(this.selectedMaterialId);
      this.getLearningMaterials();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveNewLearningMaterialAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.learningMaterialService.saveNewLearningMaterial(this.learningMaterial);
      this.ns.addToast('success', 'New lesson has been saved successfully', 'success');
      this.getLearningMaterials();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  saveExistingLearningMaterialAndUpdate = async () => {
    try {
      this.isLoading = true;
      await this.learningMaterialService.saveExistingLearningMaterial(this.learningMaterial);
      this.ns.addToast('success', 'Existing lesson has been saved successfully', 'success');
      this.getLearningMaterials();
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };

  getAllLearningMaterialTypes = async () => {
    try {
      this.isLoading = true;
      const response = await this.learningMaterialService.getAllLearningMaterialTypes();
      this.learningMaterialTypes = response;
      this.learningMaterialTypes.unshift({ displayName: 'All', Id: '', keyword: '', type: 'Unknown' });
    } catch (ex) {
      this.ns.addToast('error', getErrorMessage(ex), 'error');
    }
    this.isLoading = false;
  };
}

export default LearningMaterialStore;
