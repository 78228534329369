import { ProfileData, ProfilePictureDataType, SignUpLists } from 'types/profile';

import { AxiosInstance } from 'axios';
import { Result } from 'services/Result';
import i18next from 'i18next';

export class ProfileService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getProfile = async () => {
    const response = await this.axiosInstance.get<Result<ProfileData>>(`/api/profile/GetProfile`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching the profile data.');
  };

  getProfileById = async (id) => {
    const response = await this.axiosInstance.get(`/api/Profile/GetUserProfile`, id);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching the profile data by id.');
  };

  buildFormData(formData, data, parentKey?) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  saveProfile = async (profileData: ProfileData) => {
    const formData = new FormData();
    this.buildFormData(formData, profileData);
    const response = await this.axiosInstance.post(`/api/profile/SaveProfile`, formData, {
      headers: { 'Content-Type': 'application/json' },
    });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while saving the profile.');
  };

  getSignUpLists = async () => {
    const response = await this.axiosInstance.get<Result<SignUpLists>>(`/api/profile/SignUpLists`); //returns countries, states and categories
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while fetching the sign up lists.');
  };

  getUserLocationFromIp = async () => {
    const response = await this.axiosInstance.get(`//freegeoip.net/json/`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error("There was an internal error while fetching user's location from freegeoip.");
  };

  getUserLocationFromIp2 = async () => {
    const response = await this.axiosInstance.get(`//geoip.nekudo.com/api/`);
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error("There was an internal error while fetching user's location from geoip.");
  };

  setUserCulture = async (culture) => {
    const response = await this.axiosInstance.post(`/api/user/setculture`, { '': culture });
    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error('There was an internal error while setting user culture.');
  };

  processProfilePicAndUpdateProfile = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();

    if (blob) {
      return blob;
    }
    throw Error(i18next.t('ErrorWhileConvertingProfilePic', { ns: 'editProfile' }));
  };

  uploadProfilePicAndUpdateProfile = async (data: ProfilePictureDataType) => {
    const response = await this.axiosInstance.post(`/api/profile/UploadImage`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.data && !response.data.isError && response.data.data) {
      return response.data.data;
    }
    throw Error(i18next.t('ErrorWhileUploadingProfilePic', { ns: 'editProfile' }));
  };

  checkIfUserIsMember = async () => {
    const response = await this.axiosInstance.get<Result<boolean>>('/account/is-user-member');

    if (response.data && !response.data.isError && response.data.data !== undefined) {
      return response.data.data;
    }
    throw Error(i18next.t('MembershipCheckError', { ns: 'aboutCertification' }));
  };
}

export default ProfileService;
