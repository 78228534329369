import './StudentsList.scss';

import InviteStudentsButton from '../InviteStudentsButton/InviteStudentsButton';
import NoPedingInvitations from '../NoPedingInvitations/NoPedingInvitations';
import NoStudentsFound from '../NoStudentsFound/NoStudentsFound';
import PendingInvites from './PendingInvites/PendingInvites';
import SeeStudents from './SeeStudents/SeeStudents';
import Tab from '@mui/material/Tab';
import TableTab from '../TableTab';
import Tabs from '@mui/material/Tabs';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

export const StudentsList = observer(() => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const ss = useStores().studentsStore;
  const iss = useStores().inviteStudentsStore;

  return (
    <>
      <div className='table-tabs-wrapper'>
        <div className='tabs-wrapper'>
          <Tabs value={value} onChange={(event: React.SyntheticEvent, newValue: number) => setValue(newValue)}>
            <Tab label={t('SeeStudents', { ns: 'studentsPage', number: ss.filteredList.length })} />
            <Tab label={t('SeePendingInvites', { ns: 'studentsPage', number: iss.filteredList.length })} />
          </Tabs>
        </div>
        <InviteStudentsButton />
      </div>
      <TableTab value={value} index={0} tabPrefix='see-students-tab'>
        {!ss.students.length ? <NoStudentsFound /> : <SeeStudents />}
      </TableTab>
      <TableTab value={value} index={1} tabPrefix='pending-invites-tab'>
        {!iss.invites.length ? <NoPedingInvitations /> : <PendingInvites />}
      </TableTab>
    </>
  );
});

export default StudentsList;
