import 'bootstrap/dist/css/bootstrap.css';
import './styles/_global.scss';

import * as RoutesModule from 'Routes';

import { createContext, useContext } from 'react';

import AccountService from 'services/UserAccount/AccountService';
import AccountStore from 'stores/UserAccount/AccountStore';
import AdminSearchService from 'services/Admin/AdminSearchService';
import AdminSearchStore from 'stores/Admin/AdminSearchStore';
import App from './App';
import Area9ProgressService from 'services/CoachSection/progress/Area9ProgressService';
import Area9ProgressStore from 'stores/CoachSection/progress/Area9ProgressStore';
import { AuthenticationService } from 'services/AuthenticationService';
import AwardEditorService from 'services/Admin/Editors/AwardEditorService';
import AwardEditorStore from 'stores/Admin/Editors/AwardEditorStore';
import { AxiosErrorHandler } from 'services/AxiosErrorHandler';
import { AxiosInstance } from 'axios';
import { AxiosServiceConfig } from 'services/AxiosServiceConfig';
import { AxiosServiceFactory } from 'services/AxiosServiceFactory';
import BillingService from 'services/Subscription/BillingService';
import BillingStore from 'stores/Subscription/BillingStore';
import { BrowserRouter } from 'react-router-dom';
import BuySubscriptionService from 'services/Subscription/BuySubscriptionService';
import BuySubscriptionStore from 'stores/Subscription/BuySubscriptionStore';
import CalculatorService from 'services/Calculator/CalculatorService';
import CalculatorStore from 'stores/Calculator/CalculatorStore';
import CertificateService from 'services/UserAccount/CertificateService';
import CertificateStore from 'stores/UserAccount/CertificateStore';
import ClassesService from 'services/CoachSection/classes/ClassesService';
import ClassesStore from 'stores/CoachSection/classes/ClassesStore';
import { ConfigurationService } from 'services/ConfigurationService';
import CourseEditorService from 'services/Admin/Editors/CourseEditorService';
import CourseEditorStore from 'stores/Admin/Editors/CourseEditorStore';
import CoursesService from 'services/CoachSection/CoursesService';
import CoursesStore from 'stores/CoachSection/CoursesStore';
import DashboardService from 'services/Dashboard/DashboardService';
import DashboardStore from 'stores/Dashboard/DashboardStore';
import ExternalLoginService from 'services/UserAccount/ExternalLoginService';
import ExternalLoginStore from 'stores/UserAccount/ExternalLoginStore';
import GameProgressService from 'services/CoachSection/progress/GameProgressService';
import GameProgressStore from 'stores/CoachSection/progress/GameProgressStore';
import InviteStudentsService from 'services/CoachSection/students/InviteStudentsService';
import InviteStudentsStore from 'stores/CoachSection/students/InviteStudentsStore';
import InvoicesService from 'services/Subscription/InvoicesService';
import InvoicesStore from 'stores/Subscription/InvoicesStore';
import LanguagesService from 'services/Languages/LanguagesService';
import LanguagesStore from 'stores/Languages/LanguagesStore';
import LearningMaterialService from 'services/Admin/Editors/LearningMaterialService';
import LearningMaterialStore from 'stores/Admin/Editors/LearningMaterialStore';
import LearningMaterialsRoomLessonService from 'services/LearningMaterials/LearningMaterialsRoomLessonService';
import LearningMaterialsRoomLessonStore from 'stores/LearningMaterials/LearningMaterialsRoomLessonStore';
import LearningMaterialsRoomService from 'services/LearningMaterials/LearningMaterialsRoomService';
import LearningMaterialsRoomStore from 'stores/LearningMaterials/LearningMaterialsRoomStore';
import LessonEditorService from 'services/Admin/Editors/LessonEditorService';
import LessonEditorStore from 'stores/Admin/Editors/LessonEditorStore';
import LessonService from 'services/Lesson/LessonService';
import LessonStore from 'stores/Lesson/LessonStore';
import LtiProbeService from 'services/Lesson/LtiProbeService';
import LtiProbeStore from 'stores/Lesson/LtiProbeStore';
import ManageUserDataService from 'services/UserAccount/ManageUserDataService';
import ManageUserDataStore from 'stores/UserAccount/ManageUserDataStore';
import MasterEditorService from 'services/Admin/Editors/MasterEditorService';
import MasterEditorStore from 'stores/Admin/Editors/MasterEditorStore';
import MastersService from 'services/Masters/MastersService';
import MastersStore from 'stores/Masters/MastersStore';
import MediaService from 'services/Admin/MediaService';
import MediaStore from 'stores/Admin/MediaStore';
import { NotificationsStore } from 'stores/NotificationsStore';
import OrganizationsEditorService from 'services/Admin/Editors/OrganizationsEditorService';
import OrganizationsEditorStore from 'stores/Admin/Editors/OrganizationsEditorStore';
import OrganizationsService from 'services/CoachSection/OrganizationsService';
import OrganizationsStore from 'stores/CoachSection/OrganizationsStore';
import PageLayoutStore from 'stores/PageLayoutStore';
import PartnersService from 'services/Partners/PartnersService';
import PartnersStore from 'stores/Partners/PartnersStore';
import PasswordService from 'services/UserAccount/PasswordService';
import PasswordStore from 'stores/UserAccount/PasswordStore';
import PermissionsStore from 'stores/PermissionsStore';
import ProfileService from 'services/UserAccount/ProfileService';
import ProfileStore from 'stores/UserAccount/ProfileStore';
import QuestionEditorService from 'services/Admin/Editors/QuestionEditorService';
import QuestionEditorStore from 'stores/Admin/Editors/QuestionEditorStore';
import SavedCalcTableStore from 'stores/Calculator/SavedCalcTableStore';
import SendInvitationsTestService from 'services/StudentSection/SendInvitationsTestService';
import SendInvitationsTestStore from 'stores/StudentSection/SendInvitationsTestStore';
import StepTypesService from 'services/Admin/StepTypesService';
import StepTypesStore from 'stores/Admin/StepTypesStore';
import StudentsService from 'services/CoachSection/students/StudentsService';
import StudentsStore from 'stores/CoachSection/students/StudentsStore';
import StudyroomCourseEditorService from 'services/Admin/Editors/StudyroomCourseEditorService';
import StudyroomCourseEditorStore from 'stores/Admin/Editors/StudyroomCourseEditorStore';
import StudyroomLessonEditorService from 'services/Admin/Editors/StudyroomLessonEditorService';
import StudyroomLessonEditorStore from 'stores/Admin/Editors/StudyroomLessonEditorStore';
import TranslationsService from 'services/Languages/TranslationsService';
import TranslationsStore from 'stores/Languages/TranslationsStore';
import { createRoot } from 'react-dom/client';
import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

registerServiceWorker();

type Stores = {
  // configuration stores
  errorHandler: AxiosErrorHandler;
  configurationService: ConfigurationService;
  authenticationService: AuthenticationService;
  apiServiceAxiosInstance: AxiosInstance;
  notificationStore: NotificationsStore;
  pageLayoutStore: PageLayoutStore;
  // project data stores
  learningMaterialStore: LearningMaterialStore;
  awardEditorStore: AwardEditorStore;
  courseEditorStore: CourseEditorStore;
  certificateStore: CertificateStore;
  organizationsEditorStore: OrganizationsEditorStore;
  mediaStore: MediaStore;
  adminSearchStore: AdminSearchStore;
  classesStore: ClassesStore;
  gameProgressStore: GameProgressStore;
  area9ProgressStore: Area9ProgressStore;
  studentsStore: StudentsStore;
  inviteStudentsStore: InviteStudentsStore;
  invoicesStore: InvoicesStore;
  organizationsStore: OrganizationsStore;
  coursesStore: CoursesStore;
  dashboardStore: DashboardStore;
  translationsStore: TranslationsStore;
  languagesStore: LanguagesStore;
  ltiProbeStore: LtiProbeStore;
  lessonStore: LessonStore;
  mastersStore: MastersStore;
  partnersStore: PartnersStore;
  sendInvitationsTestStore: SendInvitationsTestStore;
  learningMaterialsRoomStore: LearningMaterialsRoomStore;
  learningMaterialsRoomLessonStore: LearningMaterialsRoomLessonStore;
  billingStore: BillingStore;
  profileStore: ProfileStore;
  accountStore: AccountStore;
  externalLoginStore: ExternalLoginStore;
  passwordStore: PasswordStore;
  lessonEditorStore: LessonEditorStore;
  masterEditorStore: MasterEditorStore;
  questionEditorStore: QuestionEditorStore;
  savedCalcTableStore: SavedCalcTableStore;
  studyroomCourseEditorStore: StudyroomCourseEditorStore;
  studyroomLessonEditorStore: StudyroomLessonEditorStore;
  stepTypesStore: StepTypesStore;
  calculatorStore: CalculatorStore;
  buySubscriptionStore: BuySubscriptionStore;
  manageUserDataStore: ManageUserDataStore;
  permissionsStore: PermissionsStore;
};

const StoresContext = createContext<Stores | null>(null);

export const useStores = () => {
  const stores = useContext(StoresContext);
  if (!stores) {
    throw new Error('useStores must be used within a StoreProvider.');
  }
  return stores;
};

async function RenderApp() {
  const errorHandler = new AxiosErrorHandler();
  const configurationService = new ConfigurationService();
  const serviceFactory = new AxiosServiceFactory(configurationService);
  const apiServiceAxiosInstance = await serviceFactory.createInstance();
  const authenticationService = new AuthenticationService();
  const notificationStore = new NotificationsStore();
  const pageLayoutStore = new PageLayoutStore();

  // services
  const learningMaterialService = new LearningMaterialService(apiServiceAxiosInstance);
  const awardEditorService = new AwardEditorService(apiServiceAxiosInstance);
  const courseEditorService = new CourseEditorService(apiServiceAxiosInstance);
  const certificateService = new CertificateService(apiServiceAxiosInstance);
  const organizationsEditorService = new OrganizationsEditorService(apiServiceAxiosInstance);
  const mediaService = new MediaService(apiServiceAxiosInstance);
  const adminSearchService = new AdminSearchService(apiServiceAxiosInstance);
  const classesService = new ClassesService(apiServiceAxiosInstance);
  const gameProgressService = new GameProgressService(apiServiceAxiosInstance);
  const area9ProgressService = new Area9ProgressService(apiServiceAxiosInstance);
  const studentsService = new StudentsService(apiServiceAxiosInstance);
  const inviteStudentsService = new InviteStudentsService(apiServiceAxiosInstance);
  const invoicesService = new InvoicesService(apiServiceAxiosInstance);
  const organizationsService = new OrganizationsService(apiServiceAxiosInstance);
  const coursesService = new CoursesService(apiServiceAxiosInstance);
  const dashboardService = new DashboardService(apiServiceAxiosInstance);
  const languagesService = new LanguagesService(apiServiceAxiosInstance);
  const ltiProbeService = new LtiProbeService(apiServiceAxiosInstance);
  const lessonService = new LessonService(apiServiceAxiosInstance);
  const mastersService = new MastersService(apiServiceAxiosInstance);
  const partnersService = new PartnersService(apiServiceAxiosInstance);
  const sendInvitationsTestService = new SendInvitationsTestService(apiServiceAxiosInstance);
  const learningMaterialsRoomService = new LearningMaterialsRoomService(apiServiceAxiosInstance);
  const learningMaterialsRoomLessonService = new LearningMaterialsRoomLessonService(apiServiceAxiosInstance);
  const billingService = new BillingService(apiServiceAxiosInstance);
  const profileService = new ProfileService(apiServiceAxiosInstance);
  const accountService = new AccountService(apiServiceAxiosInstance);
  const externalLoginService = new ExternalLoginService(apiServiceAxiosInstance);
  const passwordService = new PasswordService(apiServiceAxiosInstance);
  const lessonEditorService = new LessonEditorService(apiServiceAxiosInstance);
  const masterEditorService = new MasterEditorService(apiServiceAxiosInstance);
  const questionEditorService = new QuestionEditorService(apiServiceAxiosInstance);
  const studyroomCourseEditorService = new StudyroomCourseEditorService(apiServiceAxiosInstance);
  const studyroomLessonEditorService = new StudyroomLessonEditorService(apiServiceAxiosInstance);
  const stepTypesService = new StepTypesService(apiServiceAxiosInstance);
  const calculatorService = new CalculatorService(apiServiceAxiosInstance);
  const buySubscriptionService = new BuySubscriptionService(apiServiceAxiosInstance);
  const manageUserDataService = new ManageUserDataService(apiServiceAxiosInstance);
  const translationsService = new TranslationsService(apiServiceAxiosInstance);

  // stores
  const learningMaterialStore = new LearningMaterialStore(learningMaterialService, notificationStore);
  const awardEditorStore = new AwardEditorStore(awardEditorService, notificationStore);
  const courseEditorStore = new CourseEditorStore(courseEditorService, notificationStore);
  const certificateStore = new CertificateStore(certificateService, notificationStore);
  const organizationsEditorStore = new OrganizationsEditorStore(organizationsEditorService, notificationStore);
  const mediaStore = new MediaStore(mediaService, notificationStore);
  const adminSearchStore = new AdminSearchStore(adminSearchService, notificationStore);
  const gameProgressStore = new GameProgressStore(gameProgressService, notificationStore);
  const area9ProgressStore = new Area9ProgressStore(area9ProgressService, notificationStore);
  const organizationsStore = new OrganizationsStore(organizationsService, notificationStore);
  const invoicesStore = new InvoicesStore(invoicesService, notificationStore);
  const classesStore = new ClassesStore(classesService, organizationsStore, notificationStore);
  const inviteStudentsStore = new InviteStudentsStore(inviteStudentsService, organizationsStore, classesStore, notificationStore);
  const studentsStore = new StudentsStore(studentsService, organizationsStore, classesStore, notificationStore);
  const coursesStore = new CoursesStore(coursesService, notificationStore);
  const dashboardStore = new DashboardStore(dashboardService, notificationStore);
  const languagesStore = new LanguagesStore(languagesService, notificationStore);
  const translationsStore = new TranslationsStore(languagesStore, translationsService, notificationStore);
  const ltiProbeStore = new LtiProbeStore(ltiProbeService, notificationStore);
  const lessonStore = new LessonStore(lessonService, notificationStore);
  const mastersStore = new MastersStore(mastersService, notificationStore);
  const partnersStore = new PartnersStore(partnersService, notificationStore);
  const sendInvitationsTestStore = new SendInvitationsTestStore(sendInvitationsTestService, notificationStore);
  const learningMaterialsRoomStore = new LearningMaterialsRoomStore(learningMaterialsRoomService, notificationStore);
  const learningMaterialsRoomLessonStore = new LearningMaterialsRoomLessonStore(learningMaterialsRoomLessonService, notificationStore);
  const billingStore = new BillingStore(billingService, notificationStore);
  const profileStore = new ProfileStore(profileService, notificationStore);
  const accountStore = new AccountStore(accountService, notificationStore);
  const externalLoginStore = new ExternalLoginStore(externalLoginService, notificationStore);
  const passwordStore = new PasswordStore(passwordService, notificationStore);
  const lessonEditorStore = new LessonEditorStore(lessonEditorService, notificationStore);
  const masterEditorStore = new MasterEditorStore(masterEditorService, notificationStore);
  const questionEditorStore = new QuestionEditorStore(questionEditorService, notificationStore);
  const savedCalcTableStore = new SavedCalcTableStore(notificationStore);
  const studyroomCourseEditorStore = new StudyroomCourseEditorStore(studyroomCourseEditorService, notificationStore);
  const studyroomLessonEditorStore = new StudyroomLessonEditorStore(studyroomLessonEditorService, notificationStore);
  const stepTypesStore = new StepTypesStore(stepTypesService, notificationStore);
  const calculatorStore = new CalculatorStore(calculatorService, notificationStore);
  const buySubscriptionStore = new BuySubscriptionStore(buySubscriptionService, notificationStore);
  const manageUserDataStore = new ManageUserDataStore(manageUserDataService, notificationStore);
  const permissionsStore = new PermissionsStore(courseEditorService, organizationsService);

  const stores: Stores = {
    // configuration stores
    errorHandler,
    configurationService,
    authenticationService,
    apiServiceAxiosInstance,
    notificationStore,
    pageLayoutStore,
    // project data stores
    learningMaterialStore,
    awardEditorStore,
    courseEditorStore,
    certificateStore,
    organizationsEditorStore,
    mediaStore,
    adminSearchStore,
    classesStore,
    gameProgressStore,
    area9ProgressStore,
    studentsStore,
    invoicesStore,
    inviteStudentsStore,
    organizationsStore,
    coursesStore,
    dashboardStore,
    translationsStore,
    languagesStore,
    ltiProbeStore,
    lessonStore,
    mastersStore,
    partnersStore,
    sendInvitationsTestStore,
    learningMaterialsRoomStore,
    learningMaterialsRoomLessonStore,
    billingStore,
    profileStore,
    accountStore,
    externalLoginStore,
    passwordStore,
    lessonEditorStore,
    masterEditorStore,
    questionEditorStore,
    savedCalcTableStore,
    studyroomCourseEditorStore,
    studyroomLessonEditorStore,
    stepTypesStore,
    calculatorStore,
    buySubscriptionStore,
    manageUserDataStore,
    permissionsStore,
  };

  translationsStore.initialize();

  // Add a request and response interceptor
  AxiosServiceConfig.setDefaultDecorators(apiServiceAxiosInstance, authenticationService, errorHandler);

  root.render(
    <BrowserRouter>
      <StoresContext.Provider value={stores}>
        <App>{RoutesModule.routes}</App>
      </StoresContext.Provider>
    </BrowserRouter>
  );
}

RenderApp();
