import '../../../../pages/Admin/Admin.scss';
import '../../../../styles/_table.scss';

import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as PlusIcon } from 'images/icons/PlusInCircle.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useStores } from 'index';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel;
};

type RowsDisplay = {
  id: string;
  name: string;
};

export const AvailableOrganizationsTable = observer(({ dataItem }: Props) => {
  const org = useStores().organizationsEditorStore;

  const rowsDisplay: RowsDisplay[] = useMemo(
    () =>
      org.organizationsList
        .filter((org) => org.id && !dataItem.organizationIds.includes(org.id))
        .map((org) => {
          return {
            id: org.id,
            name: org.name,
          };
        }) as RowsDisplay[],
    [JSON.stringify(dataItem.organizationIds), org.organizationsList]
  );

  return (
    <div className='tmu-table-wrapper admin-edit-section'>
      <div className='table-subheading'>
        <strong>Organizations</strong>
      </div>
      <Table sx={{ minWidth: 750 }} className='tmu-table admin-table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsDisplay.length ? (
            rowsDisplay.map((org) => {
              return (
                <TableRow hover key={org.id}>
                  <TableCell>
                    <button className='row-button' onClick={() => dataItem.organizationIds?.push(org.id)}>
                      <PlusIcon />
                    </button>
                    {org.name}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell>
                <Paragraph>
                  <>No organizations left to add.</>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
});

export default AvailableOrganizationsTable;
