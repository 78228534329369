import '../../../pages/Admin/Admin.scss';

import { observer } from 'mobx-react-lite';

type Props = {
  jsonPreview: string;
};

export const JSONPreview = observer(({ jsonPreview }: Props) => {
  return (
    <div className='json-wrapper'>
      <pre>{jsonPreview}</pre>
    </div>
  );
});

export default JSONPreview;
