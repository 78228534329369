import { AxiosInstance } from 'axios';
import i18next from 'i18next';

type TokenHandlerResponse =
  | {
      status: 'tokenReturned';
      token: string;
      isSubscriptionPurchased: false;
      errorMessage?: undefined;
    }
  | {
      status: 'success';
      token?: undefined;
      isSubscriptionPurchased: true;
      errorMessage?: undefined;
    }
  | {
      status: 'error';
      token?: undefined;
      isSubscriptionPurchased: false;
      errorMessage: string;
    };

export class BuySubscriptionService {
  private axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  tokenHandler = async (
    tokenId: string,
    email: string,
    planCode: string,
    currency: string,
    couponCode: string
  ): Promise<TokenHandlerResponse> => {
    const response = await this.axiosInstance.post(`/api/Payment/CreateNewSubscription`, {
      TokenId: tokenId,
      Email: email,
      PlanCode: planCode,
      Currency: currency,
      CouponCode: couponCode,
    });

    if (response.data) {
      if (response.status === 406) {
        //if 406 is returned it means that 3D secure handling is required and we need to save params
        return {
          status: 'tokenReturned',
          token: response.data,
          isSubscriptionPurchased: false,
        }; //threeDSecureActionTokenId is needed to start 3D Secure challenge
      } else if (!response.data.isError && response.data.data === true) {
        return { status: 'success', isSubscriptionPurchased: true };
      } else {
        return {
          status: 'error',
          isSubscriptionPurchased: false,
          errorMessage: response.data,
        };
      }
    }

    throw Error(i18next.t('3DSecureTokenError', { ns: 'organizationPage' }));
  };

  sendThreeDSecureRequest = async (
    tokenId: string,
    email: string,
    planCode: string,
    currency: string,
    couponCode: string,
    threeDSecureTokenId: string
  ) => {
    const response = await this.axiosInstance.post(`/api/Payment/CreateNewSubscription`, {
      TokenId: tokenId,
      Email: email,
      PlanCode: planCode,
      Currency: currency,
      CouponCode: couponCode,
      ThreeDSecureToken: threeDSecureTokenId,
    });

    if (response.data) return response;

    throw Error(i18next.t('3DSecureRequestError', { ns: 'organizationPage' }));
  };

  logRecurlyApiError = async (error) => {
    const response = await this.axiosInstance.post(`/api/Payment/LogError`);
    if ((response.data && !response.data.isError && response.data.data, { Error: error })) {
      return response.data.data;
    }
    throw Error(i18next.t('RecurlyAPIError', { ns: 'organizationPage' }));
  };
}

export default BuySubscriptionService;
