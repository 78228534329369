import './TrackmanParameters.scss';

import parametersData, { parametersTitleToTab } from './parametersData';

import DOMPurify from 'dompurify';
import { ParameterModel } from 'types/trackmanParameters';
import ParametersPlaceholder from '../../images/TrackmanParameters/ParametersPlaceholder.svg';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ThreeDModel from './ThreeDModel';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useTranslation } from 'react-i18next';

export const TrackmanParameters = observer(() => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const definitionParam = searchParams.get('definitionKey');

  useSetPageTitle({
    title: t('TrackmanParameters', { ns: 'trackmanParameters' }),
    subtitle: t('CheckOutTheDefinitions', { ns: 'learningMaterials' }),
  });

  const visibleTopic = useMemo(
    () =>
      parametersData
        .map((tab) => tab.submenu)
        .flat()
        .find((topic) => (definitionParam ? topic.key === definitionParam : parametersData[0].submenu[0])) as ParameterModel,
    [definitionParam]
  );

  const activeTab = useMemo(() => {
    if (definitionParam) {
      return parametersData.findIndex((tab) => (definitionParam ? parametersTitleToTab[definitionParam] === tab.title : 0));
    }
    return 0;
  }, [definitionParam]);

  DOMPurify.addHook('afterSanitizeAttributes', (node: HTMLElement) => {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
    if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
      node.setAttribute('xlink:show', 'new');
    }
  });

  return (
    <div className='definitions-container'>
      <Tabs value={activeTab}>
        {parametersData.map((topic, index) => {
          return (
            <Tab
              label={t(topic.title, { ns: 'trackmanParameters' })}
              key={`${topic.title}-${index}`}
              onClick={() => setSearchParams({ definitionKey: parametersData[index].submenu[0].key })}
            />
          );
        })}
      </Tabs>
      {parametersData.map((topic, index) => {
        return (
          <div
            className='definitions-subject-wrapper'
            key={`${topic.title}-${index}`}
            role='tabpanel'
            hidden={activeTab !== index}
            id={`simple-tabpanel-${index}`}
          >
            <ul className='topics-list'>
              {topic.submenu.map((item, index: number) => {
                return (
                  <li
                    key={`${item.title}-${index}`}
                    onClick={() => setSearchParams({ definitionKey: item.key })}
                    className={visibleTopic?.title === item.title ? 'active' : ''}
                  >
                    {t(item.title, { ns: 'trackmanParameters' })}
                  </li>
                );
              })}
            </ul>
            <div className='topic-content-box'>
              <div className='image-wrapper'>
                {visibleTopic?.threeDModelScene ? (
                  <ThreeDModel modelScene={visibleTopic?.threeDModelScene} />
                ) : (
                  <img
                    src={visibleTopic?.image ? visibleTopic.image : ParametersPlaceholder}
                    alt={visibleTopic?.image ? visibleTopic.content : t('ImagePlaceholder', { ns: 'trackmanParameters' })}
                  />
                )}
              </div>
              <div className='topic-info'>
                <h2>{visibleTopic?.title}</h2>
                <div className='topic-description' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(visibleTopic?.content) }} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default TrackmanParameters;
