import { DexterityValue, dexterityOptionToValue } from 'types/profile';

import { ReactComponent as AdjustmentIcon } from 'images/icons/Adjustment.svg';
import { Button } from '@trackman/web-shared-components';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { ReactComponent as TourPlayerIcon } from 'images/icons/TourPlayer.svg';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStores } from 'index';
import { useTranslation } from 'react-i18next';

const handicapOptions = [...Array(42).keys()]
  .map((i) => i - 5)
  .map((integer) => {
    return {
      label: integer < 0 ? `+${integer * -1}` : integer === 36 ? '36+' : integer.toString(),
      value: integer.toString(),
    };
  });

const unitSystemOptions = [
  { label: i18next.t('Imperial', { ns: 'userCulture' }), value: 'en-US' },
  { label: i18next.t('Japanese Units', { ns: 'userCulture' }), value: 'ja-JP' },
  { label: i18next.t('Korean Units', { ns: 'userCulture' }), value: 'ko-KR' },
  { label: i18next.t('Metric', { ns: 'userCulture' }), value: 'en-GB' },
];

const categories = [
  { label: i18next.t('TeachingPro', { ns: 'editProfile' }), value: 'Teaching Pro' },
  { label: i18next.t('TourPro', { ns: 'editProfile' }), value: 'Tour Pro' },
  { label: i18next.t('ClubfitterRetail', { ns: 'editProfile' }), value: 'Clubfitter / Retail' },
  { label: i18next.t('EQM', { ns: 'editProfile' }), value: 'EQM' },
  { label: i18next.t('Amateur', { ns: 'editProfile' }), value: 'Amateur' },
  { label: i18next.t('Student', { ns: 'editProfile' }), value: 'Student' },
  { label: i18next.t('Other', { ns: 'editProfile' }), value: 'Other' },
];

export const PlayerSettingsTab = observer(() => {
  const ps = useStores().profileStore;
  const ls = useStores().languagesStore;
  const { t } = useTranslation();

  useEffect(() => {
    ls.loadLanguages();
  }, [ls]);

  return (
    <div className='profile-section profile-section--white'>
      <div className='profile-inputs-column'>
        <div className='profile-inputs-column-title'>
          <TourPlayerIcon />
          {t('PlayerInformation', { ns: 'editProfile' })}
        </div>
        <ProfileInput
          label={t('Dexterity', { ns: 'editProfile' })}
          value={ps.profileData.dexterity ?? ''}
          onChange={(newValue) => {
            ps.profileData.dexterity = newValue as DexterityValue;
          }}
          selectOptions={Object.entries(dexterityOptionToValue).map((option) => {
            return {
              label: option[0],
              value: option[1],
            };
          })}
        />
        <ProfileInput
          label={t('Handicap', { ns: 'editProfile' })}
          value={ps.profileData.handicap ?? ''}
          onChange={(newValue) => {
            ps.profileData.handicap = Number.parseFloat(newValue);
          }}
          selectOptions={handicapOptions}
        />
        <ProfileInput
          label={t('PlayerCategory', { ns: 'editProfile' })}
          value={ps.profileData.category ?? ''}
          onChange={(newValue) => {
            ps.profileData.category = newValue;
          }}
          selectOptions={categories}
        />
        <ProfileInput
          label={t('Nationality', { ns: 'editProfile' })}
          value={ps.profileData.nationality ?? ' ' ?? ''}
          onChange={(newValue) => {
            ps.profileData.nationality = newValue;
          }}
          selectOptions={ps.signUpLists.Countries.map((country) => {
            return {
              label: country.name === ' ' ? 'Please select a country' : country.name,
              value: country.name,
            };
          })}
        />
      </div>
      <div className='profile-inputs-column'>
        <div className='profile-inputs-column-title'>
          <AdjustmentIcon />
          {t('Settings', { ns: 'editProfile' })}
        </div>
        <ProfileInput
          label={t('UnitSystem', { ns: 'editProfile' })}
          value={ps.profileData.culture ?? ''}
          onChange={(newValue) => {
            ps.profileData.culture = newValue;
          }}
          selectOptions={unitSystemOptions}
        />
        <ProfileInput
          label={t('Language', { ns: 'editProfile' })}
          value={ps.profileData.language ?? ''}
          onChange={(newValue) => {
            ps.profileData.language = newValue;
          }}
          selectOptions={ls.languageInfo.map((li) => {
            return {
              label: li.displayName,
              value: li.countryCode,
            };
          })}
        />
        <Button
          className='styled-button primary'
          label={t('SaveChanges', { ns: 'editProfile' })}
          onClick={() => {
            ps.saveProfile();
          }}
          intent='primary'
          isLoading={ps.isSavingProfile}
          disabled={ps.isLoading || ps.isSavingProfile}
          placeholder={undefined}
        />
      </div>
    </div>
  );
});

export default PlayerSettingsTab;
